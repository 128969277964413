@import '~react-image-gallery/styles/scss/image-gallery.scss';
.bg-primary {
  background-color: var(--chakra-colors-primary) !important;
}
.bg-light-primary {
  background-color: var(--chakra-colors-primary, 0.15) !important;
}

.text-primary {
  color: var(--chakra-colors-primary) !important;
}

.bg-secondary {
  background-color: var(--chakra-colors-secondary) !important;
}

.text-secondary {
  color: var(--chakra-colors-secondary) !important;
}

.bg-tertiary {
  background-color: var(--chakra-colors-tertiary) !important;
}

.text-tertiary {
  color: var(--chakra-colors-tertiary) !important;
}

.bggradient-mid {
  background: radial-gradient(
    circle,
    var(--chakra-colors-primary) 3%,
    var(--chakra-colors-secondary) 70%
  );
}

.bggradient-50 {
  background: radial-gradient(
    circle,
    var(--chakra-colors-primary) 0%,
    var(--chakra-colors-secondary) 40%
  );
}

.bggradient-left {
  background: linear-gradient(
    234deg,
    var(--chakra-colors-secondary) 62%,
    var(--chakra-colors-primary) 99%
  );
}

.bggradient-right30 {
  background: linear-gradient(
    234deg,
    var(--chakra-colors-primary) 0%,
    var(--chakra-colors-secondary) 30%
  );
}

.bggradient-left30 {
  background: linear-gradient(
    234deg,
    var(--chakra-colors-secondary) 65%,
    var(--chakra-colors-primary) 100%
  );
}

//font
// @font-face {
//   font-family: 'poppins-regular';
//   src: url('../fonts/poppins/Poppins-Regular.ttf') format('opentype');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// p,
// small,
// label,
// span,
// li,
// button {
//   font-family: 'poppins-regular' !important;
// }

// body {
//   font-family: 'poppins-regular' !important;
// }

.text333 {
  color: #333333;
}

.text353535 {
  color: #353535;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #ddd;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #d1d1d1;
}

.textwhite {
  color: white !important;
}

.lheight {
  line-height: 68px;
}

.trans-btn {
  background: inherit !important;
  color: #fff !important;
  border: 1px solid #fff;
  border-radius: 0px !important;
}

.text030303 {
  color: #030303;
}

.bg-f4f5f6 {
  background-color: #f4f5f6;
}
.bg-dcdcdc{
  background-color: #dcdcdc;
}
// .cardimage {
//   background-image: url(../images//CardBg.svg);
// }
.text8787 {
  color: #878787;
}
.bg-8787 {
  background: #878787;
}
.bg-fdf3f3{
  background-color: #fdf3f3 !important;
}
.cardbgset {
  object-fit: contain !important;
  position: relative;
  top: -49px;
  height: 436px;
  width: 100%;
  left: 0px;
}

.banner-height {
  height: 505px;

  @media (max-width: 640px) {
    height: 290px !important;
  }
}

.banner-height2 {
  height: 605px;

  @media (max-width: 640px) {
    height: 290px !important;
  }
}

.chua-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 640px) {
    height: 250px !important;
  }
}

.col-62 {
  color: #626262 !important;
}

.cust-shadow {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25) !important;
}
.cust-shadow1{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
}

.smcust-shadow {
  @media (max-width: 575px) {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25) !important;
  }
}

@media (max-width: 640px) {
  .sm-help1-absl{
      top: -60% !important;
  }
  .sm-about46-vert-line {
    border-left: 1px solid gray;
    position: absolute;
    left: 30% !important;
    height: 365px !important; 
    top: 5px;
    z-index: -1;
  }
  .sm-min-w-max {
    min-width: max-content !important;
  }
  .sm-hidden {
    display: none;
  }
  .sm-w-25 {
    width: 25%;
  }

  .sm-w-100 {
    width: 100% !important;
  }
  .sm-w-50 {
    width: 50% !important;
  }
  .sm-w-60 {
    width: 60% !important;
  }

  .sm-w-70 {
    width: 70% !important;
  }

  .sm-w-75 {
    width: 75%;
  }

  .sm-rev-26 {
    padding: 20px 26px !important;
  }

  .sm-pt-4 {
    padding-top: 1rem;
  }

  .sm-pt-8 {
    padding-top: 2rem;
  }

  .sm-py-4 {
    padding: 1rem 0rem !important;
  }

  .sm-py-8 {
    padding: 2rem 0rem;
  }

  .sm-w-full {
    width: 100% !important;
  }

  .sm-mt-4 {
    margin-top: 1rem;
  }

  .sm-mb-4 {
    margin-bottom: 1rem;
  }

  .sm-px-30px {
    padding: 30px !important;
  }

  .sm-fsize16px {
    font-size: 16px !important;
  }

  .sm-fsize14 {
    font-size: 14px !important;
  }

  .sm-fsize12 {
    font-size: 12px !important;
  }

  .trans-btn {
    font-size: 14px !important;
  }

  .sm-reverse {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .sm-rev-col {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .sm-btntextwh {
    font-size: 14px !important;
    width: 150px !important;
    height: 45px !important;
  }

  .sm-none {
    display: none !important;
  }
  .sm-h200px {
    height: 200px !important;
  }
  .sm-h550px {
    height: 550px !important;
  }
  .sm-h250px {
    height: 250px !important;
  }
  .sm-h300px {
    height: 300px !important;
  }

  .banner-height {
    height: 150px !important;
  }

  .cust-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem;
  }

  .footer-menu {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem;
  }

  .cust-grid4 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem;
  }

  .front-img {
    width: 400px !important;
    height: 300px !important;
  }

  .back-img {
    width: 400px !important;
    height: 380px !important;
  }

  .front-img-div {
    inset: auto auto auto 2px !important;
    width: 220px !important;
    height: 300px !important;
  }

  .sm-h-150px {
    height: 150px !important;
  }
  .sm-h-100px {
    height: 100px !important;
  }
  .sm-bdr-leftright {
    border-left: 1px solid gray;
    border-right: 1px solid gray;
  }
  .sm-rounded-3xl {
    border-radius: 1.5rem !important;
  }
  .sm-bdr-0px {
    border-radius: 0px !important;
  }
  .mar-test12 {
    margin: auto 0px !important;
  }
  .sm-lead-2{
    margin-top: 20px !important;
  }
}

@media (max-width: 1024px) and (min-width: 641px) {
  .cust-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 0.5rem;
  }

  .tb-fsize14 {
    font-size: 14px !important;
  }

  .tb-wfull {
    width: 100% !important;
  }

  .cust-grid4 {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 0.5rem;
  }

  .footer-menu {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 0.5rem;
  }

  .tb-reverse {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .lheight {
    line-height: 48px !important;
  }

  .banner-height {
    height: 280px;
  }

  .tb-fsize23 {
    font-size: 23px !important;
  }

  .tb-h280px {
    height: 280px !important;
  }

  .abstop {
    position: absolute !important;
    top: 0 !important;
    border-radius: 20px !important;
  }
}

@media (min-width: 1024px) {
  .abstop {
    position: absolute !important;
    top: 0 !important;
    border-radius: 20px !important;
  }
}

.menuHover:hover {
  font-weight: 700;
  border-bottom: 2px solid var(--chakra-colors-primary);
}

.w-230px {
  width: 230px;
}

.clr-d9d9d9 {
  color: #d9d9d9;
}

.bg-d9d9d9 {
  background-color: #d9d9d9;
}

.clr-5a5a5a {
  color: #5a5a5a !important;
}

.clr-fff {
  color: #fff !important;
}

.lh24px {
  line-height: 24px;
}

.lineClam4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lineClamp3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lineClamp2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.input-email {
  border-radius: 30px;
  border: 1px solid #d9d9d9;
  padding: 0px 6px 0px 26px;
  width: 100%;
  height: 48px;
}

.submit-email {
  padding: 11px 38px;
  border-radius: 30px;
}

span.chakra-button__icon.css-1hzyiq5 {
  margin-left: 0px !important;
}

// .css-r6z5ec {
//   width: 100% !important;
// }

@media (max-width: 640px) {
  .sm-w-100 {
    width: 100% !important;
  }

  .sm-w-60 {
    width: 60% !important;
  }

  .sm-w-70 {
    width: 70% !important;
  }

  .sm-w-75 {
    width: 75%;
  }

  .sm-rev-26 {
    padding: 20px 26px !important;
  }

  .sm-pt-4 {
    padding-top: 1rem;
  }

  .sm-pt-8 {
    padding-top: 2rem;
  }

  .sm-py-4 {
    padding: 1rem 0rem !important;
  }

  .sm-py-8 {
    padding: 2rem 0rem;
  }

  .sm-w-full {
    width: 100% !important;
  }

  .sm-mt-4 {
    margin-top: 1rem;
  }

  .sm-mb-4 {
    margin-bottom: 1rem;
  }
  .sm-mb-0 {
    margin-bottom: 0px !important;
  }

  .sm-px-30px {
    padding: 30px !important;
  }

  .sm-fsize16px {
    font-size: 16px !important;
  }

  .sm-fsize14 {
    font-size: 14px !important;
  }

  .sm-fsize12 {
    font-size: 12px !important;
  }

  .sm-h-150px {
    height: 150px !important;
  }
  .sm-bdr-leftright {
    border-left: 1px solid gray;
    border-right: 1px solid gray;
  }
  .sm-bdr-bottom-line {
    border-bottom: 2px solid gray;
    width: 250px !important;
    margin-left: auto;
    margin-right: auto;
  }

  .sm-letter-spacing4px {
    letter-spacing: 4px !important;
  }
}
@media (min-width: 641px) and (max-width: 821px) {
  .absl-test12 {
    position: absolute;
    top: 8% !important;
    right: 20% !important;
  }
  .md-absl-news9-1 {
    left: -40px !important;
    top: -40px !important;
}
  .md-fsize30 {
    font-size: 30px !important;
  }

  .md-fsize20 {
    font-size: 20px;
  }

  .md-fsize40 {
    font-size: 40px;
  }

  .md-custdirection {
    display: flex;
    flex-direction: column-reverse;
  }

  .sm-h-screen {
    height: 100% !important;
  }

  .trans-btn {
    font-size: 14px !important;
  }

  .md-reverse {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .md-btntextwh {
    font-size: 14px !important;
    width: 150px !important;
    height: 45px !important;
  }

  .lheight {
    line-height: 42px;
  }
  .ruby-text {
    display: ruby-text !important;
  }
}
@media (max-width: 640px) {
  .sm-none {
    display: none !important;
  }

  .sm-h250px {
    height: 250px !important;
  }
  .sm-h300px {
    height: 300px !important;
  }

  .sm-h600px {
    height: 600px !important;
  }

  .banner-height {
    height: 150px !important;
  }

  .cust-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem;
  }

  .footer-menu {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem;
  }

  .cust-grid4 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem;
  }

  .front-img {
    width: 400px !important;
    height: 300px !important;
  }

  .back-img {
    width: 400px !important;
    height: 380px !important;
  }

  .front-img-div {
    inset: auto auto auto 2px !important;
    width: 220px !important;
    height: 300px !important;
  }
}

@media (max-width: 1024px) and (min-width: 641px) {
  .cust-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 0.5rem;
  }

  .tb-fsize14 {
    font-size: 14px !important;
  }

  .tb-wfull {
    width: 100% !important;
  }

  .cust-grid4 {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 0.5rem;
  }

  .footer-menu {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 0.5rem;
  }

  .tb-reverse {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .lheight {
    line-height: 48px !important;
  }

  .banner-height {
    height: 280px;
  }

  .tb-fsize23 {
    font-size: 23px !important;
  }

  .tb-h280px {
    height: 280px !important;
  }

  .abstop {
    position: absolute !important;
    top: 0 !important;
    border-radius: 20px !important;
  }
}

@media (min-width: 1024px) {
  .abstop {
    position: absolute !important;
    top: 0 !important;
    border-radius: 20px !important;
  }
}

.clr-d9d9d9 {
  color: #d9d9d9;
}

.bg-d9d9d9 {
  background-color: #d9d9d9;
}

.clr-5a5a5a {
  color: #5a5a5a;
}

.clr-fff {
  color: #fff !important;
}

.lh24px {
  line-height: 24px;
}

.lineClam4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lineClamp3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lineClamp2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.input-email {
  border-radius: 30px;
  border: 1px solid #d9d9d9;
  padding: 0px 6px 0px 26px;
  width: 100%;
  height: 48px;
}

.submit-email {
  padding: 11px 38px;
  border-radius: 30px;
}

span.chakra-button__icon.css-1hzyiq5 {
  margin-left: 0px !important;
}

// .css-r6z5ec {
//   width: 100% !important;
// }

.phoneinput .form-control {
  width: 100% !important;
  padding: 0.5rem 14px 0.5rem 50px !important;
}

.phoneinputDark .form-control {
  width: 100% !important;
  padding: 0.5rem 14px 0.5rem 50px !important;
  background-color: transparent !important;
  border: 1px solid #454a53 !important;
  border-radius: 0.375rem !important;
  color: #ffffffeb !important;
}

.react-tel-input .country-list {
  width: 460px !important;
}

.verifyemail {
  background: #6a2c70;
  padding: 3px 10px;
  border-radius: 5px;
  color: #fff;
  top: 16px;
  cursor: pointer;
}

.resendotp-t [type='text'] {
  border: 1px solid #a6adb778;
  font-size: 14px;
  border-radius: 6px;
  width: 40px !important;
  height: 40px !important;
}

.react-tel-input .special-label {
  display: none;
  position: absolute;
  z-index: 1;
  font-size: 13px;
  left: 25px;
  top: -7px;
  background: #fff;
  padding: 0 5px;
  white-space: nowrap;
}

.react-tel-input .form-control {
  font-size: 16px;
  background: transparent;
  border: 1px solid #cacaca;
  border-radius: 5px;
  width: 300px;
  outline: none;
  padding: 18.5px 14px 18.5px 60px;
  transition: box-shadow ease 0.25s, border-color ease 0.25s;
  color: #495057;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  border-radius: 3px 0 0 3px;
}

.textred {
  color: red;
}

.custcolor:hover {
  color: var(--chakra-colors-primary);
}

@media (max-width: 640px) {
  .mob-hidden {
    display: none !important;
  }
}

@media (min-width: 641px) {
  .web-hidden {
    display: none;
  }
}

.custfixed {
  position: fixed;
  z-index: 9999;
}

.navbar {
  height: 60px;
  background-color: #fef7e5;
  position: relative;
}

.menu-icon {
  display: none;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #2f234f;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 59px;
    background-color: #fff;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 260px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}

.cutimage.chakra-wrap__listitem {
  margin: auto !important;
}

.flecol .chakra-wrap__list {
  display: flex;
  align-items: center;
}

@media (max-width: 640px) {
  .custcolrev {
    flex-direction: column-reverse;
  }
}

.css-r6z5ec {
  width: 0% !important;
  z-index: 3 !important;
}

// dropdown css

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  right: 0px;
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.br-40px {
  border-radius: 40px;
}

.border-6px {
  border: 6px solid #fff;
}

.hero-header {
  font-size: 40px;
  line-height: 54px;
  white-space: normal;
  font-weight: 600;
}

// height
.h-680px {
  height: 680px;
}

.h-580px {
  height: 580px;
}

.h-450px {
  height: 450px;
}

.h-400px {
  height: 400px;
}

.h-280px {
  height: 280px;
}

.h-120px {
  height: 120px;
}

.padresponsive .marquee {
  display: flex !important;
  justify-content: space-evenly !important;
}

@media (max-width: 640px) {
  .padresponsive {
    padding-top: 70px;
  }
}

.img-custom {
  width: 100%;
  height: 100px;
}

.w-20per {
  width: 20%;
}

.w-30 {
  width: 30%;
}
.w-30per {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.wid-45 {
  width: 45%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}
.w-70per {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.marque2 {
  top: -70px;
  height: 160px;
  right: auto;
  bottom: auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 25px 10px;
  padding: 40px 0px;
  background-color: white;
  border-radius: 8px;
}

.pb-180px {
  padding-bottom: 180px;
}

.card {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  border: 0px solid var(--chakra-colors-primary);
}

// .card:hover {
//   border-top: 10px solid var(--chakra-colors-primary);
// }

.card-img {
  width: 60px;
  height: 60px;
}

.card-img2 {
  border: 0px solid var(--chakra-colors-primary);
  border-radius: 16px;
  height: 350px;
  width: 100%;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 6px,
    rgb(225, 225, 225) 0px 0px 0px 7px;
}

.card-img2:hover {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
  border-bottom: 10px solid var(--chakra-colors-primary);
}

.testimonial-card {
  padding: 24px;
  // background-color: #ffffff;
  // box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1) !important;
}

.testimonial-div {
  width: 90px;
  height: 80px;
}

.testimonial-div1 {
  width: 100px;
  height: 80px;
}

.testimonial-img {
  width: 70px;
  height: 70px;
}

.zoom {
  transition: all 0.5s ease-in-out;
}

.zoom:hover {
  transform: scale(1.1);
}

.overflow-hidden {
  overflow: hidden !important;
}

.bg-ddd9d8 {
  background-color: #ddd9d8;
}

.bg-a2a2a2 {
  background-color: #a2a2a2 !important;
}

.clr-04080C {
  color: #04080c;
}

.clr-ddd9d8 {
  color: #ddd9d8;
}

.br-ddd9d8 {
  border-color: #ddd9d8;
}

.bg-d2d2d2 {
  background-color: #d2d2d2;
}

.bg-EDF2F6 {
  background-color: #edf2f6;
}

.bg-c2bfbe {
  background-color: #c2bfbe;
}

.box-shadow-inset {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 4px inset;
}

.box-shadow-outer {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 4px;
}

.shadow-ffffff {
  box-shadow: 0 0px 0 6px #ffffff, 0 0px 0 7px #ddd9d8;
}

.shadow-ffffff:hover {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
}

.custShadow {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1) !important;
}

.custShadow1 {
  box-shadow: rgba(0, 0, 0, 0.08) 2px 2px 18px 0px;
}

// .custShadow1:hover {
//   border-width: 8px 1px 1px 1px;
//   box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 12px 8px;
// }

.custShadow2 {
  box-shadow: rgba(0, 0, 0, 0.21) 0px 0px 6px 0px;
}

.fsize86 {
  font-size: 86px;
  line-height: 110px;
}

.contactcss {
  background-color: #fff;
  padding: 2rem;
  border-radius: 16px;
}

.letter-spacing4px {
  letter-spacing: 4px;
}

.inputFeild {
  padding: 6px 16px;
  width: 100%;
  height: 60px;
  font-weight: 500;
  border-bottom: 1px solid #e4e8ec;
  outline: none;
  margin: 0px;
  background-color: #f4f5f6;
  font-size: 14px;
}

.inputFeild-dark {
  padding: 6px 16px;
  width: 100%;
  height: 60px;
  font-weight: 500;
  border-bottom: 1px solid #e4e8ec;
  outline: none;
  margin: 0px;
  font-size: 14px;
}

.inputFeild1 {
  padding: 6px 16px;
  width: 100%;
  height: 60px;
  font-weight: 500;
  border: 2px solid #e4e8ec;
  outline: none;
  margin: 0px;
  background-color: #ddd9d8;
  font-size: 14px;
}

.form-button {
  color: #f7fbff;
  background-color: #c2bfbe;
}

.hover-6f6f6f:hover {
  border-left: 10px solid #6f6f6f;
}

.hover-ddd9d8:hover {
  border-left: 10px solid #ddd9d8;
}

.teams2-img {
  width: 160px;
  height: 160px;
}

.border-top12px {
  border-top: 12px solid #c2bfbe;
}

.br-28px {
  border-radius: 28px;
}

.br-16px {
  border-radius: 16px;
}

.w-h-330px {
  width: 380px;
  height: 330px;
}

.br-2px {
  border-width: 2px;
}

.br-2px:hover {
  border-color: #ddd9d8;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.numb-otp input {
  border: 1px solid gray;
  margin-right: 10px;
  width: 40px !important;
  height: 40px !important;
  border-radius: 6px;
}

.fsize56 {
  font-size: 56px;
}

.leading-12 {
  line-height: 48px;
}

.back-img-div {
  border-top: 20px solid #c2bfbe;
  position: static;
  border-radius: 16px;
}

.back-img {
  width: 400px;
  height: 578px;
  border-radius: 0px;
  margin-top: -10px;
}

.front-img-div {
  inset: auto auto auto 135px;
  width: 314px;
  height: 438px;
  display: block;
}

.front-img {
  width: 314px;
  height: 438px;
  z-index: 1;
  border-radius: 16px;
  border-color: #ffffff;
  box-shadow: rgb(255, 255, 255) 4px 0px 0px 8px;
}

.fsize60 {
  font-size: 60px;
}

.fsize64 {
  font-size: 64px;
  line-height: 72px;
}

.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 999999999 !important;
}

.topminus35 {
  top: -15% !important;
}

.clr-9c9c9c {
  color: #9c9c9c !important;
}

.minh450px {
  @media (min-width: 576px) {
    height: 450px;
  }
}

.minh500px {
  @media (min-width: 576px) {
    height: 500px;
  }
}

.bg-ffffff4d {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.red-circle {
  width: 6px;
  height: 6px;
  background-color: var(--chakra-colors-primary) !important;
  border-radius: 100%;
}

.border1px-black {
  border: 1px solid black;
}

.border3px-fff {
  border: 3px solid #fff;
}

.rounded-right-lg {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
  border-top-left-radius: 0rem !important;
  border-bottom-left-radius: 0rem !important;
}

.rounded-left-lg {
  border-top-right-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.h45px {
  height: 45px !important;
}

input:hover {
  outline: 0px !important;
}

.h-120px {
  height: 120px !important;
}

.backdrop-filer {
  background: rgba(250, 250, 250, 0.5) !important;
  backdrop-filter: blur(10px) !important;
}

.backdrop-filer::placeholder {
  color: #000 !important;
}

// FAQ

.css-186l2rg {
  color: #9d4a4d !important;
}

//filter

.highlightOption {
  background: var(--chakra-colors-secondary) !important;
}

.multiSelectContainer li:hover {
  background: var(--chakra-colors-secondary) !important;
}

.chip {
  background: var(--chakra-colors-secondary) !important;
}

.cerashadow {
  box-shadow: rgba(34, 41, 47, 0.1) 0px 4px 24px 0px !important;
}

.menucheckii .css-r6z5ec {
  width: 100% !important;
  transform: translate3d(0px, 42px, 0px) !important;
  top: 22px !important;
}

.rem-rounded {
  border-radius: 0px !important;
}

// user-card

.minus3rem-mtop {
  margin-top: -3rem;
}

.w-10rem {
  width: 10rem;
}

.px-0px {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.bg-f4f4f4 {
  background-color: #f4f4f4;
}

.clr-515151 {
  color: #515151;
}

.clr-6f6f6f {
  color: #6f6f6f;
}

.clr-848484 {
  color: #848484;
}

.clr-ec1c24 {
  color: #ec1c24;
}

.css-1ss3utg[aria-selected='true'],
.css-1ss3utg[data-selected] {
  color: #fff !important;
  background: var(--chakra-colors-primary) !important;
  border-radius: 4px !important;
}

.css-1ss3utg {
  border-radius: 4px !important;
}

.bg-fff {
  background-color: #fff !important;
}

.fsize12px {
  font-size: 12px !important;
}

.maxheight350 {
  max-height: 350px !important;
}

.w-70px {
  width: 70px;
}

.bottom-border-ebebeb {
  border-bottom: 1px solid #ebebeb;
}

.lh-26px {
  line-height: 26px;
}

@media (max-width: 1024px) and (min-width: 641px) {
  .md-fsize18px {
    font-size: 18px !important;
  }

  .md-w-110px {
    width: 110px;
  }

  .md-w-60 {
    width: 60% !important;
  }

  .md-w-80 {
    width: 80%;
  }

  .md-w-90 {
    width: 90%;
  }

  .md-w-100 {
    width: 100%;
  }

  .md-h-100 {
    height: 100% !important;
  }

  .hero-header {
    font-size: 36px !important;
    line-height: 48px !important;
    font-weight: 600 !important;
  }

  .md-fsize64 {
    font-size: 64px;
    line-height: 80px;
  }
}

@media (max-width: 641px) {
  .sm-lh-22px {
    line-height: 22px !important;
  }

  .sm-lh-26px {
    line-height: 26px !important;
  }

  .sm-drawer-padding {
    padding: 0px 5px 0px 5px !important;
  }

  .sm-ml-8px {
    margin-left: 8px !important;
  }

  .sm-minus4rem-mtop {
    margin-top: -4rem;
  }
}

.border6px-fff {
  border: 6px solid #fff;
}

.w-65 {
  width: 65%;
}

.min-w-65 {
  @media (min-width: 576px) {
    width: 65% !important;
  }
}

//testimonial
.testimonial .coverflow__text__39hqd {
  display: none !important;
}

.testimonial .coverflow__container__1P-xE {
  height: 145px !important;
  background: none !important;
}

.testimonial .coverflow__figure__3bk_C {
  box-shadow: none !important;
  margin: 0px 0px 0px 30px !important;
  -webkit-box-reflect: unset !important;

  @media (max-width: 640px) {
    width: 80px !important;
  }
}

.testimonial .coverflow__cover__25-7e {
  width: 80px !important;
  height: 80px !important;
  border-radius: 50% !important;
}

.testimonial .coverflow__coverflow__53z9A {
  margin-top: 1px !important;
}

.roundetest {
  @media (min-width: 576px) {
    width: 70px !important;
    height: 70px !important;
  }

  @media (max-width: 575px) {
    width: 50px !important;
    height: 50px !important;
  }
}

.fillstroke {
  stroke: #fdcc0d !important;
  fill: #fdcc0d !important;
}

.activeuser {
  border: 3px solid white;
}

//plans
.bg-plancard {
  border-radius: 10px;
  backdrop-filter: blur(17.5px);
  background: rgba(255, 255, 255, 0.25);
}

.bg-plancardwhite {
  border-radius: 3px;

  background: #fafafa;
}

.fitcontent {
  width: fit-content;
}

.planstop {
  @media (min-width: 576px) {
    margin-top: -140px;
  }
}

.borderblack {
  border: 1px solid #000;
}

.cardhover {
  transition: transform 0.2s;
}

.cardhover:hover {
  transform: scale(1.1);
  z-index: 99;
}

.active {
  color: var(--chakra-colors-primary);
}

.minmaxh280px {
  @media (min-width: 576px) {
    height: 280px !important;
  }

  @media (max-width: 575px) {
    height: 180px !important;
  }
}

.reactquill-h .ql-container {
  height: 200px;
}

.rwt__tab {
  font-size: 14px !important;
}

//elearning
.bg-1c1c1c {
  background: #1c1c1c;
}

@media (min-width: 641px) and (max-width: 821px) {
  .md-fsize30 {
    font-size: 30px;
  }

  .md-fsize20 {
    font-size: 20px;
  }

  .md-fsize40 {
    font-size: 40px;
  }

  .md-custdirection {
    display: flex;
    flex-direction: column-reverse;
  }
}

.bg-343434 {
  background: #343434 !important;
}

.h-130px {
  height: 130px;
}

.h-200px {
  height: 200px;
}

.custcardcourse {
  border-radius: 0px 0px 10px 10px;
}

.custimghover:hover {
  transform: scale(1.1);
}

.h-300px {
  height: 300px;
}

.text-506380 {
  color: #506380;
}

.h-250px {
  height: 250px;
}

.text-727272 {
  color: #727272;
}

.bg-f7fbff {
  background: #f7fbff;
}

.bg-0b0b0b {
  background: #0b0b0b;
}

.bordbl {
  border-bottom: 1px solid #ececec !important;
}
.bordblack {
  border-bottom: 1px solid #222 !important;
}
.bordb-dashed {
  border-bottom: 1px dashed #777 !important;
}

.bordb-primary {
  border-bottom: 1px solid var(--chakra-colors-primary) !important;
}

.text-danger {
  color: red !important;
}

.text-dark {
  color: #111 !important;
}

// .h-400px {
//   height: 400px;
// }

.inborder {
  border: 1px solid black !important;
}

.custpointwh {
  width: 40px;
  height: 40px;
}

@media (max-width: 640px) {
  .sm-custdirection {
    display: flex;
    flex-direction: column-reverse;
  }

  .sm-h-130px {
    height: 130px !important;
  }
  .sm-h-500px {
    height: 500px !important;
  }
  .sm-lineclamp-5 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
  }

  .sm-swiper .swiper-button-next:after {
    font-size: 25px !important;
  }

  .sm-swiper .swiper-button-prev:after {
    font-size: 25px !important;
  }

  .sm-wh {
    width: 40px;
    height: 40px;
  }

  .sm-update-button {
    font-size: 14px !important;
    padding: 10px !important;
  }

  .sm-h-fit {
    height: fit-content;
  }

  .sm-clr-fff {
    color: #fff !important;
  }
  .sm-h-310px {
    height: 310px !important;
  }
  .sm-h-300px {
    height: 300px !important;
  }
}

.sm-fsize24 {
  @media (max-width: 640px) {
    font-size: 24px;
  }
}

.letter-spacing4px {
  @media (max-width: 640px) {
    letter-spacing: 0px !important;
  }
}

.sm-wfull {
  @media (max-width: 640px) {
    width: 100% !important;
  }
}

.sm-w-80 {
  @media (max-width: 640px) {
    width: 80% !important;
  }
}

.testimonial-div {
  @media (max-width: 640px) {
    display: flex !important;
    justify-content: center !important;
    width: 100%;
  }
}

.testimonial-div1 {
  @media (max-width: 640px) {
    display: flex !important;
    justify-content: center !important;
    width: 50%;
  }
}

.sm-h-250px {
  @media (max-width: 640px) {
    height: 250px !important;
  }
}

.sm-h-350px {
  @media (max-width: 640px) {
    height: 350px !important;
  }
}

.smcust-btn {
  font-size: 14px !important;
  width: 150px !important;
  height: 45px !important;
}

.newswiper {
  @media (max-width: 640px) {
    width: auto !important;
  }
}

.teams2-img {
  @media (max-width: 640px) {
    width: 100px !important;
    height: 100px !important;
  }
}

.card-img2 {
  @media (max-width: 640px) {
    height: 250px !important;
  }
}

.sm-h-200px {
  @media (max-width: 640px) {
    height: 200px !important;
  }
}

.sm-lh-32px {
  @media (max-width: 640px) {
    line-height: 32px !important;
  }
}

.sm-h-auto {
  @media (max-width: 640px) {
    height: auto !important;
  }
}

.hero-header {
  @media (max-width: 640px) {
    font-size: 24px !important;
    line-height: 32px !important;
    font-weight: 600 !important;
  }
}

.pb-180px {
  @media (max-width: 640px) {
    padding-bottom: 50px !important;
  }
}

.sm-fsize30 {
  @media (max-width: 640px) {
    font-size: 30px !important;
  }
}

.border-6d6d6d {
  border: 1px solid #6d6d6d;
}

// electronics home

.rounded-100px {
  border-radius: 100px !important;
}

.product-card {
  border: 1px solid #e9e9ed;
  border-radius: 8px;
  height: fit-content;
  display: block;
}

.product-card-dark {
  // border: 1px solid #e9e9ed;
  border-radius: 8px;
  height: fit-content;
  display: block;
}

.product-card-image {
  min-width: 16px;
  min-height: 16px;
  display: inline-block;
  object-fit: cover;
  pointer-events: auto;
  width: 100%;
  height: 10rem;
  border-radius: 8px 8px 0px 0px;
}

.lineClamp1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.strikethrough {
  text-decoration: line-through !important;
}

// electronics product

.select-Filter {
  padding: 10px 6px;
  border-radius: 6px;
}

.h-100per {
  height: 100%;
}

.counter {
  width: 20%;
  display: flex;
  align-items: center;
  border: 1px solid hsla(0, 0%, 50.2%, 0.2901960784);
  border-radius: 6px;
  justify-content: center;
}

.counter-input {
  width: 40px;
  height: 20px;
  background: none;
  border: none;
  padding: 5px;
  text-align: center;
  font-size: 14px;
}

.chips-select {
  background: #ddd;
  padding: 5px 14px;
  font-size: 13px;
  border-radius: 50px;
  font-weight: 600;
  color: #5a5a5a;
  cursor: pointer;
}

.image-gallery-play-button {
  display: none;
}

.table-padding {
  padding: 1.5rem 0.75rem !important;
  line-height: 1rem !important;
}

.wrap-text {
  text-wrap: wrap;
}

.border-r-1 {
  border-right: 1px solid #e2e8f0;
}

.bordb-ececec {
  border-bottom: 1px solid #ececec !important;
}

// electronic cart

.boxshadow-cart {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 12px 0px;
}

.h-150px {
  height: 150px;
}

.lineClamp4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.lineClamp6 {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.counter1 {
  width: 25%;
  display: flex;
  align-items: center;
  border: 1px solid hsla(0, 0%, 50.2%, 0.2901960784);
  border-radius: 6px;
  justify-content: center;
}

.bdr-rev {
  border: 1px solid gray;
  padding: 16px 18px;
  border-radius: 8px;
}

.h-file {
  height: 56px;
}

.h-file {
  height: 56px;
}

.file-absl {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 80px;
  width: 40px;
}

.btn-30 {
  padding: 20px 30px !important;
}

.pad-y-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.btn-30 {
  padding: 20px 30px !important;
}

.pad-y-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

@media (max-width: 640px) {
  .sm-w-90 {
    width: 90%;
  }

  .sm-h-full {
    height: 100%;
  }

  .h-580px {
    height: 250px !important;
  }

  .sm-lh-30 {
    line-height: 30px !important;
  }

  .sm-lh-46 {
    line-height: 46px !important;
  }
  .my-1rem {
    margin: 1rem 0rem;
  }

  .leading-12 {
    line-height: 26px;
  }

  .sm-pb-1 {
    padding-bottom: 0.25rem;
  }

  .sm-pb-2 {
    padding-bottom: 0.5rem;
  }

  .sm-pb-4 {
    padding-bottom: 1rem;
  }

  .sm-col-rev {
    flex-direction: column-reverse;
  }

  .sm-lh-24 {
    line-height: 24px !important;
  }

  .mt-8px {
    margin-top: 8px;
  }

  .mob-hidden {
    display: none;
  }

  .web-hidden {
    display: block !important;
  }

  .w-70 {
    width: 100%;
  }

  .my-1rem {
    margin: 1rem 0rem;
  }

  .sm-h-350px {
    height: 350px;
  }

  .sm-py-6 {
    padding: 1.5rem 0rem;
  }

  .leading-12 {
    line-height: 26px;
  }

  .sm-pb-1 {
    padding-bottom: 0.25rem;
  }

  .sm-pb-2 {
    padding-bottom: 0.5rem;
  }

  .sm-pb-4 {
    padding-bottom: 1rem;
  }

  .sm-col-rev {
    flex-direction: column-reverse;
  }

  .mt-8px {
    margin-top: 8px;
  }

  .mob-hidden {
    display: none;
  }

  .sm-justify-center {
    justify-content: center;
  }
  .sm-absl-transform {
    position: absolute;
    bottom: 6%;
    left: 50%;
    -webkit-transform-origin-x: -50%;
    transform: translate(-50%);
  }
}

.web-hidden {
  display: block !important;
}

.filtercard {
  background: white;
  margin: 0px 0px 0px 0px;
  padding: 8px 8px 8px 8px;
  border-radius: 10px;
  border: 1px solid #e9e9ed;
}

.filtercard-dark {
  background: transparent;
  margin: 0px 0px 0px 0px;
  padding: 8px 8px 8px 8px;
  border-radius: 10px;
}

.css-1kfu8nn {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.ml-10px {
  margin-left: 10px;
}

.mb-2px {
  margin-bottom: 2px !important;
}

.mb-4px {
  margin-bottom: 4px !important;
}

.ml-10px {
  margin-left: 10px;
}

.mb-2px {
  margin-bottom: 2px !important;
}

.chakra-checkbox__label {
  font-size: 14px !important;
}

.ml-10px {
  margin-left: 10px;
}

.mb-2px {
  margin-bottom: 2px !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 36px;
  width: 20px;
}

.btn-30 {
  padding: 20px 30px !important;
}

.btn-30 {
  padding: 20px 30px !important;
}
@media (max-width: 768px) {
  .chakra-modal__content {
    margin-left: 10px;
  }
}

@media (max-width: 820px) and (min-width: 641px) {
  .md-lead-2{
    margin-top: -260px !important;
  }
  .counter {
    width: 35%;
  }
  
.md-about46-vert-line {
  border-left: 1px solid gray;
  position: absolute;
  left: 30% !important;
  height: 390px !important; 
  top: 5px;
  z-index: -1;
}
  .chips-select {
    background: #ddd;
    padding: 10px 18px;
    font-size: 14px;
    border-radius: 50px;
    font-weight: 600;
    color: #5a5a5a;
    cursor: pointer;
  }
  .md-absl-transform {
    position: absolute;
    bottom: 4% !important;
    left: 50%;
    -webkit-transform-origin-x: -50%;
    transform: translate(-50%);
  }
  .md-bdr-bottom-line {
    border-bottom: 2px solid gray;
    width: 150px !important;
  }
  .md-col-span-2-1 {
    grid-column: 2 / span 1;
  }
  .md-row-span-1-1 {
    grid-row: 1 / span 1 !important;
  }
}

@media (max-width: 820px) and (min-width: 641px) {
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 36px;
    width: 20px;
  }

  .counter1 {
    width: 35%;
  }
  .md-h-250px {
    height: 250px;
  }
  .md-font-25 {
    font-size: 22px !important;
  }

  .md-font-28 {
    font-size: 28px !important;
  }

  .md-font-30 {
    font-size: 30px !important;
  }

  .md-font-28 {
    font-size: 28px !important;
  }

  .md-font-25 {
    font-size: 22px !important;
  }

  .md-font-28 {
    font-size: 28px !important;
  }

  .md-font-30 {
    font-size: 30px !important;
  }

  .md-font-28 {
    font-size: 28px !important;
  }

  .md-font-14 {
    font-size: 14px;
  }

  .md-w-70 {
    width: 70%;
  }

  .md-font-25 {
    font-size: 22px !important;
  }

  .md-font-28 {
    font-size: 28px !important;
  }

  .md-font-30 {
    font-size: 30px !important;
  }

  .md-font-28 {
    font-size: 28px !important;
  }

  .md-font-14 {
    font-size: 14px;
  }

  .md-lh-48 {
    line-height: 48px !important;
  }

  .md-w-30 {
    width: 30%;
  }

  .md-w-70 {
    width: 70%;
  }

  .btn-30 {
    padding: 20px 30px !important;
  }

  .md-h-150px {
    height: 150px !important;
  }

  .md-h-200px {
    height: 200px !important;
  }

  .md-h-350px {
    height: 350px !important;
  }

  .md-h-250px {
    height: 250px !important;
  }

  .md-h-300px {
    height: 300px !important;
  }
  .md-h-450px {
    height: 450px !important;
  }
}

.add-btn {
  font-size: 12px !important;
  padding: 20px 28px !important;
}

.add-btn {
  font-size: 12px !important;
  padding: 20px 28px !important;
}

.chips-select {
  font-size: 14px;
}

.leading-34px {
  line-height: 34px;
}

.contactcss {
  background-color: #fff;
  padding: 1rem;
  border-radius: 16px;
}

.chips-select {
  font-size: 14px;
}

.contactcss {
  background-color: #fff;
  padding: 1rem;
  border-radius: 16px;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 36px;
  width: 20px;
}

.btn-30 {
  padding: 20px 30px !important;
}

.height-fit {
  height: fit-content;
}

.pr-9rem {
  padding-right: 9rem !important;
}

.w-100per {
  width: 100% !important;
}

.wishlist-card {
  box-shadow: rgba(0, 0, 0, 0.25) 1.63px 1.63px 6px 0px;
  border-radius: 8px;
  height: fit-content;
  display: block;
}

.wishlist-card-image {
  min-width: 16px;
  min-height: 16px;
  display: inline-block;
  object-fit: cover;
  pointer-events: auto;
  width: 100%;
  height: 240px;
  border-radius: 8px 8px 0px 0px;
}

.wishlist-x-icon {
  background: white;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 12px 0px;
  border-radius: 50%;
}

.web-hidden {
  display: none !important;
}

.css-12vdn2q {
  margin: 0px !important;
}

.header-3-profile-icon {
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: auto !important;
  background: none !important;
}

.header-3-profile-icon .chakra-button__icon {
  color: white !important;
}

//sbilayout-css
.border-primary {
  border: 1px solid var(--chakra-colors-primary) !important;
}

.border-2px-primary {
  border: 2px solid var(--chakra-colors-primary) !important;
}
.border-4px-primary {
  border: 4px solid var(--chakra-colors-primary) !important;
}
.border-hr {
  border: 5px solid var(--chakra-colors-primary) !important;
  width: 100px !important;
}

.backbanner {
  background: linear-gradient(
    rgba(11, 118, 177, 1),
    rgba(84, 193, 255, 1)
  ) !important;
}

.swiper-wrapper {
  display: flex !important;
}

.textprimary {
  color: var(--chakra-colors-primary) !important;
}

.bgsecondary {
  background: var(--chakra-colors-secondary) !important;
}

.textsecondary {
  color: var(--chakra-colors-secondary) !important;
}

// .border-primary {
//   border: 1px solid #29166f !important;
// }

.border-hr {
  border: 5px solid #7dd0ff !important;
  width: 25px !important;
}

.backbanner {
  background: linear-gradient(
    rgba(11, 118, 177, 1),
    rgba(84, 193, 255, 1)
  ) !important;
}

.bordb {
  border-bottom: 1px solid #fff !important;
}

.bordbl {
  border-bottom: 1px solid #ececec !important;
}

.dropdowns .chakra-menu__menu-list {
  min-width: auto !important;
}

.dropdowns .chakra-menu__menuitem {
  font-size: 13px !important;
  padding: 9px 30px 9px 10px !important;
  border-bottom: 1px solid #ececec !important;
}

.cards-about {
  width: 403px !important;
  height: 500px !important;

  @media (max-width: 768px) and (min-width: 642px) {
    width: 300px !important;
    height: 350px !important;
  }

  @media (max-width: 640px) {
    width: 86% !important;
    height: 300px !important;
  }
}

.cards-home {
  height: 500px !important;

  @media (max-width: 820px) and (min-width: 642px) {
    height: 400px !important;
  }

  @media (max-width: 640px) {
    height: 100% !important;
  }
}

.h-banner {
  height: 420px !important;

  @media (max-width: 640px) {
    height: 250px !important;
  }
}

.list-dot {
  list-style: disc !important;
  margin-left: 20px !important;
}

@media (max-width: 640px) {
  .cust-reverse {
    flex-direction: column-reverse !important;
  }

  .sm-line-clamp3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

//  ========= Navbar Css =========
.side-btn {
  width: 35px !important;
  height: 35px !important;
  background: #ececec !important;
}

.h-100vh {
  height: 100vh !important;
}

.bg-navbar {
  background: #0000006c !important;
}

//  ========= Navbar Css =========

//  ========= Home Css =========
.home-h {
  width: 100% !important;
  height: 650px !important;

  @media (max-width: 640px) {
    height: 400px !important;
  }
}

.cards-img {
  height: 500px !important;
  margin-right: -30px;

  @media (max-width: 820px) and (min-width: 642px) {
    height: 400px !important;
  }

  @media (max-width: 640px) {
    height: 250px !important;
  }
}

.cards2-img {
  width: 400px !important;
  height: 500px !important;

  @media (max-width: 768px) and (min-width: 642px) {
    width: 300px !important;
    height: 350px !important;
  }
}
.ml-10px {
  margin-left: 10px;
}

.mb-2px {
  margin-bottom: 2px !important;
}

.mb-4px {
  margin-bottom: 4px !important;
}

.ml-10px {
  margin-left: 10px;
}

.mb-2px {
  margin-bottom: 2px !important;
}

.chakra-checkbox__label {
  font-size: 14px !important;
}

.ml-10px {
  margin-left: 10px;
}

.mb-2px {
  margin-bottom: 2px !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 36px;
  width: 20px;
}

.btn-30 {
  padding: 20px 30px !important;
}

.btn-30 {
  padding: 20px 30px !important;
}
@media (max-width: 768px) {
  .chakra-modal__content {
    margin-left: 10px;
  }
}

@media (max-width: 820px) and (min-width: 641px) {
  .counter {
    width: 35%;
  }

  .chips-select {
    background: #ddd;
    padding: 10px 18px;
    font-size: 14px;
    border-radius: 50px;
    font-weight: 600;
    color: #5a5a5a;
    cursor: pointer;
  }
}

@media (max-width: 820px) and (min-width: 641px) {
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 36px;
    width: 20px;
  }

  .counter1 {
    width: 35%;
  }
  .md-h-250px {
    height: 250px;
  }
  .md-font-25 {
    font-size: 22px !important;
  }

  .md-font-28 {
    font-size: 28px !important;
  }

  .md-font-30 {
    font-size: 30px !important;
  }

  .md-font-28 {
    font-size: 28px !important;
  }

  .md-font-25 {
    font-size: 22px !important;
  }

  .md-font-28 {
    font-size: 28px !important;
  }

  .md-font-30 {
    font-size: 30px !important;
  }

  .md-font-28 {
    font-size: 28px !important;
  }

  .md-font-14 {
    font-size: 14px;
  }

  .md-w-70 {
    width: 70%;
  }

  .md-font-25 {
    font-size: 22px !important;
  }

  .md-font-28 {
    font-size: 28px !important;
  }

  .md-font-30 {
    font-size: 30px !important;
  }

  .md-font-28 {
    font-size: 28px !important;
  }

  .md-font-14 {
    font-size: 14px;
  }

  .md-lh-24 {
    line-height: 24px;
  }

  .md-lh-36 {
    line-height: 36px;
  }

  .md-lh-48 {
    line-height: 48px !important;
  }

  .md-w-30 {
    width: 30%;
  }

  .md-w-70 {
    width: 70%;
  }

  .btn-30 {
    padding: 20px 30px !important;
  }

  .md-h-150px {
    height: 150px !important;
  }

  .md-h-200px {
    height: 200px !important;
  }

  .md-h-300px {
    height: 300px !important;
  }
  .md-h-600px {
    height: 600px !important;
  }

  .md-h-650px {
    height: 650px !important;
  }
  .md-h-700px {
    height: 700px !important;
  }
  .absolute-mid {
    position: absolute;
    top: -70px !important;
    left: 31px !important;
  }
  .md-cust-fsize40 {
    font-size: 40px;
    line-height: 50px;
  }
}

.add-btn {
  font-size: 12px !important;
  padding: 20px 28px !important;
}

.add-btn {
  font-size: 12px !important;
  padding: 20px 28px !important;
}

.chips-select {
  font-size: 14px;
}

.leading-34px {
  line-height: 34px;
}

.contactcss {
  background-color: #fff;
  padding: 1rem;
  border-radius: 16px;
}

.chips-select {
  font-size: 14px;
}

.contactcss {
  background-color: #fff;
  padding: 1rem;
  border-radius: 16px;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 36px;
  width: 20px;
}

.btn-30 {
  padding: 20px 30px !important;
}

.height-fit {
  height: fit-content;
}

.pr-9rem {
  padding-right: 9rem !important;
}

.w-100per {
  @media (max-width: 640px) {
    width: 100% !important;
    height: 300px !important;
  }
}

.absolute-cards {
  margin-top: -30px !important;

  @media (max-width: 768px) and (min-width: 642px) {
    margin-top: -10px !important;
  }

  @media (max-width: 640px) {
    margin-top: -20px !important;
  }
}

.absolute2-cards {
  margin-top: -60px !important;
  margin-left: 30px !important;

  @media (max-width: 768px) and (min-width: 642px) {
    margin-top: -10px !important;
    margin-left: 10px !important;
  }

  @media (max-width: 640px) {
    margin-top: -20px !important;
    margin-left: 26px !important;
  }
}

.col-79 {
  color: #797979 !important;
}

.col-9e {
  color: #9e9e9e !important;
}

.bg-fa {
  background: #fafafa !important;
}

.falgship-h {
  width: 100% !important;
  height: 500px !important;

  @media (max-width: 640px) {
    height: 360px !important;
  }
}

.discover-h {
  width: 100% !important;
  height: 500px !important;

  @media (max-width: 640px) {
    height: 280px !important;
  }
}

.iframe-h {
  height: 410px !important;

  @media (max-width: 640px) {
    height: 250px !important;
  }
}

.impact-h {
  width: 100% !important;
  height: 400px !important;

  @media (max-width: 640px) {
    height: 500px !important;
  }
}

.chairman-h {
  width: 100% !important;
  height: 800px !important;

  @media (max-width: 820px) and (min-width: 642px) {
    height: 100% !important;
  }

  @media (max-width: 640px) {
    height: 100% !important;
  }
}

.map-img {
  width: 100% !important;
  height: 550px !important;

  @media (max-width: 820px) and (min-width: 642px) {
    height: 350px !important;
  }

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

.border-side {
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;

  @media (max-width: 640px) {
    border-left: none !important;
    border-right: none !important;
  }
}

.form-img {
  width: 100% !important;
  height: 600px !important;

  @media (max-width: 640px) {
    height: 100vh !important;
  }
}

.address-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

.bg-ffffffa8 {
  background: #ffffffbb !important;
}

//  ========= Home Css =========

//  ========= About Css =========
.foundation-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 820px) and (min-width: 642px) {
    height: 350px !important;
  }

  @media (max-width: 640px) {
    height: 280px !important;
  }
}

.vision-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 820px) and (min-width: 642px) {
    height: 350px !important;
  }

  @media (max-width: 640px) {
    height: 280px !important;
  }
}

.mission-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 820px) and (min-width: 642px) {
    height: 350px !important;
  }

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

//  ========= About Css =========

//  ========= SBI Ace Css =========
.creating-img {
  width: 100% !important;
  height: 550px !important;

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

//  ========= SBI Ace Css =========

//  ========= Asha Scholarship Css =========
.scholarship-img {
  width: 100% !important;
  height: 550px !important;

  @media (max-width: 820px) and (min-width: 642px) {
    height: 300px !important;
  }

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

//  ========= Asha Scholarship Css =========

//  ========= ILM Css =========
.ilm-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 820px) and (min-width: 642px) {
    height: 300px !important;
  }

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

.objective-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

//  ========= ILM Css =========

//  ========= Working Css =========
.working-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 820px) and (min-width: 642px) {
    height: 300px !important;
  }

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

//  ========= Working Css =========

//  ========= Women Css =========
.women-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

//  ========= Women Css =========

//  ========= Youth Css =========
.youth-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

.fellowship-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

.application-h {
  height: 450px !important;

  @media (max-width: 640px) {
    height: 300px !important;
  }
}

//  ========= Youth Css =========

//  ========= Leap Css =========
.overviews-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

.support-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

//  ========= Leap Css =========

//  ========= Jivanam Css =========
.empowering-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

.col-14 {
  color: #141414 !important;
}

//  ========= Jivanam Css =========

//  ========= Maintenance Css =========
// .h-100vh {
//   height: 100vh !important;

//   @media (max-width: 640px) {
//     height: 97% !important;
//   }
// }

.text-a5 {
  color: #a5deff !important;
}

.z-ind-1 {
  z-index: 1;
  position: relative;
}

.maintenance-content {
  text-align: center;
}

.sbi-logo {
  width: 200px;
}

.bg-7DD0ff {
  background-color: #7dd0ff;
}

.bg-29166f {
  background-color: #29166f;
}

.col-29166f {
  color: #29166f;
}

.col-5c5c5e {
  color: #5c5c5e;
}

.border-dark {
  border: 1px solid #b8b8b8 !important;
}

.bg-d8 {
  background: #d8d8d8 !important;
}

.bg-f4 {
  background: #f4f4f4 !important;
}

.border-file {
  border: 5px solid rgba(0, 182, 240, 0.09) !important;
  background: rgba(231, 246, 255, 1);
}

.col-8c {
  color: #8c8c8c !important;
}

.font400we {
  font-weight: 400;
}

.image-swiper-button-prevs {
  position: absolute !important;
  z-index: 9999 !important;
  left: 5px !important;
  top: 44% !important;
  color: var(--qtheme-color-secondary) !important;
}

.image-swiper-button-nexts {
  position: absolute !important;
  z-index: 9999 !important;
  right: 5px !important;
  top: 44% !important;
  color: var(--qtheme-color-secondary) !important;
}
.image-swiper-button-prevz {
  position: absolute !important;
  z-index: 9999 !important;
  left: 5px !important;
  top: 35% !important;
  color: var(--qtheme-color-secondary) !important;
}

.image-swiper-button-nextz {
  position: absolute !important;
  z-index: 9999 !important;
  right: 5px !important;
  top: 35% !important;
  color: var(--qtheme-color-secondary) !important;
}
.mob-swiper .swiper-slide {
  width: auto !important;
}

.bg-no-repeat {
  background-repeat: no-repeat;
  background-size: cover;
}

.ttxbfg {
  height: 450px;
}

@media (max-width: 640px) {
  .mobtext-justify {
    text-align: justify !important;
  }
}

.file-icon {
  width: 70px !important;
  height: 60px !important;

  @media (max-width: 575px) {
    margin: auto !important;
    padding-top: 10px;
    width: 40px !important;
    height: 40px !important;
  }
}

.h-content {
  height: 350px !important;
  overflow-y: auto !important;
  padding: 26px !important;

  @media (max-width: 1400px) and (min-width: 769px) {
    height: 240px !important;
  }

  @media (max-width: 640px) {
    padding: 16px !important;
  }
}

.maintenanceImg {
  width: 100%;
  height: 100vh;
  background-image: url('https://nimbuscluster.blob.core.windows.net/server01/SBI%20Foundation/sbifoundation/sbifundation/Desktop%20-%202.png');

  @media (max-width: 575px) {
    width: 100%;
    background-image: url('https://nimbuscluster.blob.core.windows.net/server01/SBI%20Foundation/sbifoundation/sbifundation/Desktop%20-%203.png');
  }
}

.w-600px {
  width: 600px;

  @media (max-width: 575px) {
    width: 100%;
  }
}

.border-new {
  border-radius: 30px 30px 0 0 !important;

  @media (max-width: 768px) {
    border-radius: 0px !important;
  }
}

.border-new2 {
  border-radius: 15px 15px 0 0 !important;

  @media (max-width: 768px) {
    border-radius: 0px !important;
  }
}

.logos {
  width: 80px;
  height: 80px;

  @media (max-width: 640px) {
    width: 100% !important;
  }
}

.min-h475px {
  @media (min-width: 576px) {
    height: 475px !important;
  }
}

.bgadd-image {
  @media (min-width: 576px) {
    width: 100%;
    background-image: url('https://nimbuscluster.blob.core.windows.net/server01/SBI%20Foundation/sbifoundation/sbifundation/Ellipse%201.png');
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-position: unset;
    position: fixed;
    bottom: 0;
    height: 450px;
    background-size: cover;
  }

  @media (max-width: 575px) {
    width: 100%;
    background-image: url('https://nimbuscluster.blob.core.windows.net/server01/SBI%20Foundation/sbifoundation/sbifundation/Ellipse%202.png');
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-position: bottom;
    position: fixed;
    bottom: 0;
    background-position: unset;
    background-size: cover;
    height: 400px;
  }
}

.smfsize10 {
  @media (max-width: 575px) {
    font-size: 12px;
  }
}

//===========Footer Css=========//
.text-626262 {
  color: #626262;
}

.text-959595 {
  color: #959595;
}

.text-29166f {
  color: #29166f;
}

.text-cb {
  color: #cbcbcb;
}

.text-black {
  color: #000 !important;
}

//  ========= Maintenance Css =========//

//  ========= Swiper Css =========//
.direction
  .swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullets.swiper-pagination-horizontal {
  top: 50% !important;
  bottom: 50% !important;
  right: 4px !important;
  left: auto !important;
  width: auto !important;
  display: grid !important;
  gap: 6px !important;
}

//  ========= Swiper Css =========//
.error-img {
  width: 400px !important;
  height: 400px !important;
}

.error-404 {
  width: 500px !important;
  height: 500px !important;
}

.mar-right {
  margin-right: -12% !important;
  margin-top: -4% !important;
}

.mar-left {
  margin-left: -12% !important;
  margin-top: -4% !important;
}

.contact-dot {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.filter-b4 {
  filter: brightness(0.5) !important;
}

.popup-img {
  height: 450px !important;

  @media (max-width: 575px) {
    height: 200px !important;
  }
}

// help support

.css-1u2cvaz {
  background: #e7e7e75e !important;
}

.border-accordion {
  border-width: 1px !important;
  border-radius: 6px !important;
}

.pd-6px {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.sel .css-161pkch {
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  padding: 10px 10px !important;
}

.edit_icon {
  background: #8f8f8f;
  color: #fff;
}

.clr-7b7b7b {
  color: #7b7b7b;
}

.react-reveal {
  opacity: 1 !important;
}

.rounded-3px {
  border-radius: 3px;
}

@media (max-width: 575px) {
  .sm-lead {
    line-height: 40px !important;
  }
}

.borderall {
  border: 1px solid #e9e9ed !important;
}

.list-notdot {
  list-style: none !important;
}

.cus-bannerdynamic::after {
  top: 116px !important;
}

.cus-featuredynamic::after {
  top: 46px !important;
}

//test & jasmeena

.cust-hw {
  height: 60px;
  width: 60px;
}

.bdr {
  border: 1px solid #e4e8ec;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: 'next';
  font-size: 30px !important;
  color: black;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: 'prev';
  font-size: 30px !important;
  color: black;
}

// .star-hw {
//   height: 16px;
//   width: 16px;
// }

.img-h {
  height: 600px;
}

.bg-fffaf0 {
  background: #fffaf0;
}

.absl {
  position: absolute;
  top: 30%;
}

.btn-bdr {
  border: 2px solid var(--chakra-colors-primary) !important;
}

.wid-70 {
  width: 70%;
}

.wid-30 {
  width: 30%;
}

.img-wh {
  width: 450px;
  height: 180px;
}

.cmnt-h {
  height: 105px;
}

.test-h {
  height: 210px;
}

.inp-bdr {
  border: 2px solid lightgray;
}

.h-750 {
  height: 750px;
}

.gal-img {
  height: 380px;
}

.steps {
  width: 35px;
  height: 35px;
  font-size: 14px;
  color: white;
  background: var(--chakra-colors-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.vert-line {
  border-left: 1px solid var(--chakra-colors-primary);
  position: absolute;
  left: 18px;
  height: 210px;
  top: 5px;
  z-index: -1;
}

.fab-wh {
  width: 100%;
  height: 450px;
}

.cat-wh {
  height: 220px;
}

.txt-absl {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
}

.clr-fffaf0 {
  color: #fffaf0;
}

.bdr-r {
  border: solid var(--chakra-colors-primary);
  border-width: 2px 1px 2px 2px;
}

.bdr-l {
  border: solid var(--chakra-colors-primary);
  border-width: 2px 2px 2px 1px;
}

.hr-w {
  border-top-width: 2px;
  border-color: var(--chakra-colors-secondary);
}

.wid-20per {
  width: 20%;
}

.wid-80per {
  width: 80%;
}

@media (max-width: 640px) {
  .sm-overflow-x {
    overflow-x: scroll;
    width: 100%;
    display: -webkit-inline-box;
    padding-bottom: 0px;
  }
}
.cust-hw {
  height: 50px;
  width: 50px;
}

.bdr {
  border: 1px solid #e4e8ec !important;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: 'next';
  font-size: 18px !important;
  color: black;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: 'prev';
  font-size: 18px !important;
  color: black;
}

.star-hw {
  height: 13px;
  width: 13px;
}

.h-750 {
  height: 100%;
}

.gal-img {
  height: 250px;
  width: 100%;
}

.steps {
  width: 24px;
  height: 24px;
  font-size: 12px;
  color: white;
  background: var(--chakra-colors-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  padding-bottom: 2px;
}

.w-5 {
  width: 15%;
}

.vert-line {
  border-left: 1px solid gray;
  position: absolute;
  left: 12px;
  height: 220px;
  top: 5px;
  z-index: -1;
}

.about46-vert-line {
  border-left: 1px solid gray;
  position: absolute;
  left: 28%;
  height: 390px;
  top: 5px;
  z-index: -1;
}

.fab-wh {
  width: 100%;
  height: 250px;
}

.cat-wh {
  height: 160px;
}

.cmnt-h {
  height: 85px;
}

.bdr-r {
  border: solid var(--chakra-colors-primary);
  border-width: 1px 1px 0px 1px;
}

.bdr-l {
  border: solid var(--chakra-colors-primary);
  border-width: 1px 1px 1px 1px;
}

.hr-w {
  border-top-width: 1px;
  border-color: #da8f2c;
}

@media (max-width: 640px) {
  .sm-overflow-x {
    overflow-x: scroll;
    width: 100%;
    display: -webkit-inline-box;
    padding-bottom: 0px;
  }

  .cust-hw {
    height: 50px;
    width: 50px;
  }

  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: 'next';
    font-size: 18px !important;
    color: black;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    content: 'prev';
    font-size: 18px !important;
    color: black;
  }

  .star-hw {
    height: 13px;
    width: 13px;
  }

  .img-h {
    height: 300px;
  }

  .absl {
    position: absolute;
    top: 20%;
  }

  .wid-70 {
    width: 100%;
  }

  .h-750 {
    height: 100%;
  }

  .gal-img {
    height: 250px;
    width: 100%;
  }

  .steps {
    width: 24px;
    height: 24px;
    font-size: 12px;
    color: white;
    background: var(--chakra-colors-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    padding-bottom: 2px;
  }

  .w-5 {
    width: 15%;
  }

  .vert-line {
    border-left: 1px solid gray;
    position: absolute;
    left: 12px;
    height: 220px;
    top: 5px;
    z-index: -1;
  }

  .fab-wh {
    width: 100%;
    height: 250px;
  }

  .cat-wh {
    height: 160px;
  }

  .cmnt-h {
    height: 85px;
  }

  .bdr-r {
    border: solid var(--chakra-colors-primary);
    border-width: 1px 1px 0px 1px;
  }

  .bdr-l {
    border: solid var(--chakra-colors-primary);
    border-width: 1px 1px 1px 1px;
  }

  .hr-w {
    border-top-width: 1px;
    border-color: #da8f2c;
  }

  .sm-w-6rem {
    width: 6rem;
  }

  .sm-mx-auto {
    margin: 0px auto;
  }

  .sm-center {
    display: flex;
    justify-content: center;
  }

  .sm-wid-10per {
    width: 10%;
  }

  .sm-wid-90per {
    width: 90%;
  }

  .sm-btn-dual {
    padding: 8px 24px !important;
  }

  .sm-px-10px {
    padding: 0px 10px !important;
  }

  .sm-star-16 {
    width: 16px;
    height: 16px;
  }

  .sm-px-1rem {
    padding: 0 1rem !important;
  }

  .sm-px-05rem {
    padding: 0 0.5rem !important;
  }

  .sm-pb-6 {
    padding-bottom: 1.5rem;
  }

  .sm-w-2rem {
    width: 2rem;
  }
}

@media (max-width: 820px) and (min-width: 641px) {
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: 'next';
    font-size: 22px !important;
    color: black;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    content: 'prev';
    font-size: 22px !important;
    color: black;
  }

  .testimonial-img {
    width: 60px;
    height: 60px;
  }

  .md-wid-100 {
    width: 100%;
  }

  .img-h {
    height: 450px;
  }

  .h-750 {
    height: 500px;
  }

  .gal-img {
    height: 300px;
    width: 100%;
  }

  .w-5 {
    width: 8%;
  }

  .vert-line {
    height: 235px;
  }

  .md-fab-wh {
    width: 100% !important;
    height: 300px !important;
  }

  .md-star-18 {
    width: 18px;
    height: 18px;
  }

  .md-star-12 {
    width: 12px;
    height: 12px;
  }

  .creating-img {
    width: 100% !important;
    height: 350px !important;
  }
  .md-h-400px {
    height: 400px !important;
  }
  .md-h-450 {
    height: 450px !important;
  }
  .md-w-full {
    width: 100%;
  }
}

.flex_rating {
  display: flex !important;
}

.addcms_card {
  padding: 10px;
  border-radius: 4px;
  background: #fff;
}

.search-abs {
  position: absolute;
  right: 14px;
  top: 8px;
}

.bg-fafafa {
  background-color: #fafafa;
}

.bg-c3c3c3 {
  background-color: #c3c3c3;
}

.borderright {
  border-right: 1px solid;
}

// scv

.dot {
  width: 12px;
  height: 12px;
  background-color: var(--chakra-colors-primary);
  border-radius: 100%;
}

.btn-dual {
  padding: 12px 36px !important;
}

.bgclr-black {
  background: black !important;
}

.clr-white {
  color: white !important;
}

.box-shdw {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.px-3rem {
  padding: 0 3rem;
}

.tab_css {
  background: #f4f5f6;
  padding: 10px;
  border-radius: 999px;
}
@media (max-width: 640px) {
  .sm-center {
    display: flex;
    justify-content: center;
  }

  .sm-wid-10per {
    width: 10%;
  }

  .sm-wid-90per {
    width: 90%;
  }

  .sm-btn-dual {
    padding: 8px 24px !important;
  }

  .sm-px-10px {
    padding: 0px 10px !important;
  }

  .sm-star-16 {
    width: 16px;
    height: 16px;
  }

  .sm-px-1rem {
    padding: 0 1rem !important;
  }

  .sm-px-05rem {
    padding: 0 0.5rem !important;
  }

  .sm-pb-6 {
    padding-bottom: 1.5rem;
  }
  .sm-mb-6 {
    margin-bottom: 1.5rem;
  }
  .sm-cards2-img {
    width: 400px !important;
    height: 300px !important;
  }
}

@media (max-width: 820px) and (min-width: 641px) {
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: 'next';
    font-size: 22px !important;
    color: black;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    content: 'prev';
    font-size: 22px !important;
    color: black;
  }
}
.testimonial-img {
  width: 60px;
  height: 60px;
}

.h-swipertestimonial {
  height: 300px !important;
  @media (max-width: 768px) and (min-width: 641px) {
    height: 280px !important;
  }
  @media (max-width: 640px) {
    height: 100% !important;
  }
}
.h-swipertestimonial8 {
  height: 300px !important;
  @media (max-width: 768px) and (min-width: 641px) {
    height: 280px !important;
  }
  @media (max-width: 640px) {
    height: 100% !important;
  }
}
.gallerybox:hover:before {
  top: 0 !important;
}
.gallerybox:before {
  content: '' !important;
  background: var(--chakra-colors-primary);
  opacity: 0.5 !important;
  height: 50% !important;
  width: 100% !important;
  left: 0px !important;
  z-index: 1 !important;
  top: -50% !important;
  transition: all 0.4s ease-in 0s;
  position: absolute;
}
.gallerybox:hover:after {
  bottom: 0 !important;
}
.gallerybox:after {
  content: '';
  background: var(--chakra-colors-primary);
  opacity: 0.5 !important;
  height: 50% !important;
  width: 100% !important;
  left: 0px !important;
  z-index: 1 !important;
  bottom: -50% !important;
  position: absolute;
  transition: all 0.4s ease-in 0s;
}
.gallery-content {
  opacity: 0;
}
.gallerybox:hover .gallery-content {
  opacity: 1;
  transition: all 0.4s ease-in 0s;
}

.box-shadow-none {
  box-shadow: none !important;
}
.plus-opacity {
  opacity: 0;
}
.gallerybox:hover .plus-opacity {
  opacity: 0.6;
  transition: all 0.5s ease-in;
}
// button effect
.btn-effect {
  background: #ececec;
  color: var(--chakra-colors-primary);
  overflow: hidden;
}
.btn-effect:hover {
  position: relative;
  z-index: 7;
  color: #fff !important;
}
.btn-effect:hover:before {
  width: 110%;
  height: 200px;
  background: var(--chakra-colors-primary);
  left: 50%;
  color: #fff !important;
  top: 50%;
  z-index: -1;
  transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
  transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
}
.btn-effect:before {
  content: '';
  position: absolute;
  width: 0px;
  color: #fff !important;
  height: 0px;
  top: 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
  border-radius: 70%;
  transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 1);
}

.gradient:hover:after {
  bottom: 0 !important;
}
.gradients:after {
  content: '';
  background: linear-gradient(rgba(22, 28, 36, 0) 0%, rgb(22, 28, 36) 85%);
  height: 100% !important;
  width: 100% !important;
  left: 0px !important;
  z-index: 1 !important;
  bottom: 0% !important;
  position: absolute;
}
.gradient:after {
  content: '';
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent);
  height: 100% !important;
  width: 100% !important;
  left: 0px !important;
  z-index: 1 !important;
  bottom: -60% !important;
  position: absolute;
  transition: all 0.2s ease-in 0s;
}
.text-gradient {
  display: none;
}
.gradient:hover .text-gradient {
  display: block;
  transition: all 0.1s ease-in 0s;
}
.h-gallery {
  height: 41.5rem !important;
  @media (max-width: 768px) and (min-width: 641px) {
    height: 21.5rem !important;
  }
  @media (max-width: 640px) {
    height: 10.5rem !important;
  }
}
.text-4rem {
  font-size: 4rem;
}
.bckdrop {
  backdrop-filter: brightness(0.5);
}

.herovideo-height {
  @media (max-width: 575px) {
    height: 250px !important;
    object-fit: fill;
  }

  @media (max-width: 768px) {
    height: 400px;
    object-fit: fill;
  }
}

.bg-darkprimary {
  background-color: #1a202c !important;
}

.border-0 {
  border: 0 !important;
}

.border-1px {
  border-width: 1px !important;
}

.border-b-1px {
  border-bottom-width: 1px;
}

.border-r-1px {
  border-right-width: 1px;
}
.react-calendar {
  width: 100% !important;
  background: transparent !important;
  border: none !important;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 1rem !important;
  background: #fafafa !important;
  @media (max-width: 768px) and (min-width: 641px) {
    padding: 0.2rem !important;
  }
  @media (max-width: 640px) {
    padding: 0.2rem !important;
  }
}
.react-calendar__tile--active {
  background: var(--chakra-colors-primary) !important;
  border-radius: 5px !important;
}
.react-calendar button {
  font-size: 16px !important;
  padding: 20px !important;
  @media (max-width: 768px) and (min-width: 641px) {
    font-size: 16px !important;
    padding: 10px !important;
  }
  @media (max-width: 640px) {
    font-size: 14px !important;
    padding: 8px !important;
  }
}

.fade-right {
  opacity: 0;
  transform: translate3d(100px, 0, 0);
}

.visibleright {
  opacity: 1;
  transform: translateZ(0);
  transition-property: opacity, transform;
  transition-timing-function: ease;
  transition-duration: 2s;
}
.fade-left {
  opacity: 0;
  transform: translate3d(-100px, 0, 0);
}
.visibleleft {
  opacity: 1;
  transform: translateZ(0);
  transition-property: opacity, transform;
  transition-timing-function: ease;
  transition-duration: 2s;
}

.fade-top {
  opacity: 0;
  transform: translate3d(0, -50px, 0);
}

.visibletop {
  opacity: 1;
  transform: translateZ(0);
  transition-property: opacity, transform;
  transition-timing-function: ease;
  transition-duration: 2s;
}

.fade-bottom {
  opacity: 0;
  transform: translate3d(0, 50px, 0);
}

.visiblebottom {
  opacity: 1;
  transform: translateZ(0);
  transition-property: opacity, transform;
  transition-timing-function: ease;
  transition-duration: 2s;
}

.img-dis {
  display: none;
  position: absolute !important;
  top: 0%;
  right: -50%;
}

.image-product:hover .img-dis {
  display: block;
  right: 3%;
  transition: all 0.3s ease;
}

@media (max-width: 820px) and (min-width: 641px) {
  .md-wid-100 {
    width: 100%;
  }

  .img-h {
    height: 450px;
  }

  .h-750 {
    height: 500px;
  }

  .gal-img {
    height: 300px;
    width: 100%;
  }

  .w-5 {
    width: 8%;
  }

  .vert-line {
    height: 235px;
  }

  .md-fab-wh {
    width: 100% !important;
    height: 300px !important;
  }

  .md-star-18 {
    width: 18px;
    height: 18px;
  }
  .creating-img {
    width: 100% !important;
    height: 350px !important;
  }
  .md-h-400px {
    height: 400px;
  }
  .md-h-450 {
    height: 450px !important;
  }
  .md-w-full {
    width: 100%;
  }
}

.flex_rating {
  display: flex !important;
}

.addcms_card {
  padding: 10px;
  border-radius: 4px;
  background: #fff;
}

.search-abs {
  position: absolute;
  right: 14px;
  top: 8px;
}

.bg-fafafa {
  background-color: #fafafa;
}

.bg-c3c3c3 {
  background-color: #c3c3c3;
}

.borderright {
  border-right: 1px solid;
}

.c-flex {
  display: flex !important;
}

.menu_width180px {
  width: 180px !important;
}

.justifybetween {
  justify-content: space-between !important;
}

.h200px {
  height: 200px;
}

.txt-gray {
  color: gray !important;
}

// file-uploader

.uploader {
  border: 2px dashed #d9d9d9 !important;
  height: 180px !important;
  width: 100%;
  border-radius: 10px;
}

.uploader-input {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 99;
}

.h-180px {
  height: 180px;
}

.cust-bgf5f5f5 {
  background-color: #f5f5f5;
}

.modalcss {
  display: flex;
  background: #fff;
  height: 100% !important;
  align-items: center;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.h-chat {
  height: 600px !important;
  overflow-y: auto;

  @media (max-width: 640px) {
    height: 500px !important;
  }
}

.h-chatscroll {
  height: 500px !important;
  overflow-y: auto;

  @media (max-width: 640px) {
    height: 400px !important;
  }
}

.minus-dropdown {
  margin-top: -7px !important;
}

.circle {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50% !important;
  box-shadow: 0 0 1 1 #0000001a !important;

  @media (max-width: 640px) {
    width: 50px !important;
    height: 50px !important;
  }
}

.circle-2 {
  width: 110px !important;
  height: 110px !important;
  border-radius: 50% !important;

  @media (max-width: 640px) {
    width: 60px !important;
    height: 60px !important;
  }
}

.pulse {
  animation: pulse-animation 4s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 50px rgba(0, 0, 0, 0);
  }
}

.pulse2 {
  animation: pulse2-animation 3s infinite;
}

@keyframes pulse2-animation {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
  }
}

.max-chat {
  max-width: 450px !important;
  border-radius: 10px 15px 0 10px !important;

  @media (max-width: 640px) {
    max-width: 250px !important;
  }
}

.max-chat2 {
  max-width: 450px !important;
  border-radius: 15px 10px 10px 0px !important;

  @media (max-width: 640px) {
    max-width: 250px !important;
  }
}

.w-250px {
  width: 250px;
}

.bg-ffefd0 {
  background: #ffefd0 !important;
}

.bg-e1e1e1 {
  background-color: #e1e1e1;
}

.custwidth {
  width: 100px;
  height: 75px;
}

.text-053c6d {
  color: #053c6d;
}

.csticon .chakra-popover__close-btn {
  color: #ff6600;
}

.custborder1 {
  border: 1px solid #d1d1d1;
  padding: 15px;
  border-radius: 10px;
}

.btnradius.chakra-button {
  border-radius: 100px !important;
  background: #ff6600 !important;
  color: white;
  font-size: 14px !important;
  font-weight: 300 !important;
}

.preradius.chakra-button {
  border-radius: 100px !important;
  background: #053c6d !important;
  color: white;
  font-size: 14px !important;
  font-weight: 300 !important;
}

.bg-e1e1e1 {
  background-color: #e1e1e1;
}
.image-block {
  position: relative;
  width: 100%;
  height: 450px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6), 0 1px 2px rgba(0, 0, 0, 0.12);
  transition: all 1.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
  @media (max-width: 640px) {
    height: 350px;
  }
}
.image-block:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  .text-hover {
    opacity: 0;
    transform: scale(0.5);
  }
  .images-blog {
    transform: scale(2.85);
  }
  .figcap {
    bottom: 0;
  }
}
.text-hover {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;
  color: white;
  font-weight: 500;
  line-height: 1;
  opacity: 1;
  transform: scale(0.9);
  transition: 0.2s ease;
  z-index: 999;
}
img {
  height: 100%;
  transition: 0.25s;
}
.figcap {
  position: absolute;
  bottom: -59%;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 30px;
  background-color: rgba(black, 0.5);
  color: white;
  line-height: 1;
  transition: 0.25s;
  @media (max-width: 640px) {
    bottom: -83%;
  }
}
.leading-para {
  line-height: 24px !important;
}

.heart {
  color: red;
}
.w-350px {
  width: 350px;
}
.filterr .css-r6z5ec {
  @media (max-width: 640px) {
    width: 65% !important;
  }
}

// Profile-2 css

.profile-img {
  width: 240px;
  height: 240px;
  overflow: hidden;
  margin: -180px auto 21px;
  border-radius: 20px;

  @media (max-width: 820px) and (min-width: 641px) {
    margin: 0 auto 21px;
  }

  @media (max-width: 640px) {
    margin: 0 auto 21px;
  }
}

.social-media-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  cursor: pointer;
}
.social-media-icon:hover {
  color: #fff !important;
  background: var(--chakra-colors-primary) !important;
}

.social-icon {
  flex: 0 0 auto;
  display: inline-block;
  width: 44px;
  height: 44px;
  background-color: #fff;
  border-radius: 8px;
  text-align: center;
  line-height: 44px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08);
  transition: 0.3s;
  font-size: 22px;
  cursor: pointer;
}

.social-icon:hover {
  background-color: var(--chakra-colors-primary);
  color: #fff;
}

.pad-6px {
  padding: 6px;
}

.pad-10px {
  padding: 10px;
}

.fixed-side {
  position: sticky !important;
  margin-left: 0;
  border-radius: 20px;
  background-color: #fff;
  padding: 40px 30px;
  top: 200px;

  @media (max-width: 820px) and (min-width: 641px) {
    top: 0px;
    position: inherit !important;
  }

  @media (max-width: 640px) {
    padding: 30px 20px;
    top: 0px;
    position: inherit !important;
  }
}

.bg-f3f6f6 {
  background-color: #f3f6f6;
}

.bottom-border-e3e3e3 {
  border-bottom: 1px solid #e3e3e3;
}

.tab-size {
  width: 90px;
}

.tab-header {
  display: flex !important;
  justify-content: flex-end !important;
  border-bottom: none !important;

  @media (max-width: 640px) {
    display: flex !important;
    justify-content: space-between !important;
    border-bottom: none !important;
  }
}

.bg-fff4f4 {
  background-color: #fff4f4;
}

.bg-eef5fa {
  background-color: #eef5fa;
}

.black-dot {
  width: 5px;
  height: 5px;
  background-color: black;
  border-radius: 100%;
}

.bg-f8fbfd {
  background-color: #f8fbfd;
}

.bg-e7ebeb {
  background-color: #e7ebeb;
}

.br-12px {
  border-radius: 12px;
}

.br-20px {
  border-radius: 20px;
}

.br-top-20px {
  border-radius: 20px 20px 0 0;
}

.br-bottom-20px {
  border-radius: 0 0 20px 20px;
}

.selected_tab[aria-selected='true'] {
  background-color: var(--chakra-colors-primary) !important;
}
.booking-img {
  height: 100vh !important;
  @media (max-width: 640px) {
    display: none;
  }
}
.clsss .css-13cymwt-control {
  line-height: 50px !important;
  background-color: transparent !important;
  border-color: #525a68 !important;
  @media (max-width: 640px) {
    line-height: 45px !important;
  }
}
.clsss .css-t3ipsp-control {
  line-height: 50px !important;
  @media (max-width: 640px) {
    line-height: 45px !important;
  }
}
.contact-area {
  padding: 60px 45px;
  border-radius: 20px;

  @media (max-width: 820px) and (min-width: 641px) {
    padding: 30px 20px;
  }

  @media (max-width: 640px) {
    padding: 30px 20px;
  }
}

.input-box {
  display: block;
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #b5b5b5;
  background: transparent;
  height: 40px;
  // transition: .3s;
  // transform-origin: top;
  font-size: 14px;
}

.form-btn {
  font-size: 16px !important;
  font-weight: 500;
  display: inline-block;
  text-transform: capitalize;
  color: #1a1a1a !important;
  height: 50px !important;
  width: 133px !important;
  border: 1px solid #44566c !important;
  border-radius: 12px !important;
  background-color: #f8fbfd !important;
}

.form-btn:hover {
  background: var(--chakra-colors-primary) !important;
  border-color: transparent !important;
  color: #fff !important;
}

.btn-2 {
  font-size: 18px !important;
  font-weight: 400 !important;
  color: black !important;
  background: #fff !important;
  line-height: 27px !important;
  padding: 12px 30px !important;
  border-radius: 10px !important;
  border: 1px solid black !important;
  transition: 0.3s !important;
}

.btn-2:hover {
  background: var(--chakra-colors-primary) !important;
  transform: translateY(-2px);
  border: 1px solid transparent !important;
  color: #fff !important;
}

.tab-title {
  background-color: #f3f6f6;
  border-radius: 12px;
  width: 84px;
  height: 84px;
  transition: 0.3s;
}

.tab-title1 {
  background-color: #f3f6f6;
  border-radius: 12px;
  width: 120px;
  height: 84px;
  transition: 0.3s;
}

.tab-title-active {
  background: var(--chakra-colors-primary) !important;
  color: #fff;
  border-radius: 12px;
  width: 84px;
  height: 84px;
  transition: 0.3s;
}

.tab-title-active1 {
  background: var(--chakra-colors-primary) !important;
  color: #fff;
  border-radius: 12px;
  width: 120px;
  height: 84px;
  transition: 0.3s;
}

.minwidth-70 {
  min-width: 70%;
}

.minheight-800 {
  min-height: 800px;

  @media (max-width: 640px) {
    min-height: 800px;
  }
}

.height-20rem {
  height: 20rem !important;
}

.advanced-cropper {
  background-color: white !important;
}
//teams7
.h-teams7 {
  height: 41.5rem !important;
  @media (max-width: 768px) and (min-width: 641px) {
    height: 21.5rem !important;
  }
  @media (max-width: 640px) {
    height: 10.5rem !important;
  }
}
.featureimage {
  display: none;
}
.borderfeature {
  border: 2px solid #f3eaea;
}
.colorcard {
  color: #222;
}
.hover-images:hover .colorcard {
  color: #fff;
}
.hover-images:hover .borderfeature {
  border: none;
}
.hover-images:hover .featureimage {
  display: flex;
}
// Blogs css

.vr {
  border-right: 1px solid #9f9d9d;
}
.arrow-r {
  height: 20px;
  width: 20px;
}
.blog-abs {
  position: absolute;
  top: 20px;
  right: 20px;
  background: white;
  border-radius: 4px;
  padding: 6px;
}
.blg-hov:hover {
  background-color: var(--chakra-colors-primary);
  color: white;
}
.hr-blg {
  border-top-width: 2px;
  border-color: var(--chakra-colors-primary);
  margin: auto;
  width: 15%;
}

// Testimonial 8 css

.wh-20 {
  width: 20px;
  height: 20px;
}
.topr-abs {
  position: absolute;
  top: -40px;
  right: 10%;
  z-index: 1;
}
.img-wh-80 {
  height: 80px;
  width: 80px;
}
.img-h-350 {
  height: 350px;
}
.w-90per {
  width: 90%;
}
.w-10per {
  width: 10%;
}
.img-wh-65 {
  width: 65px;
  height: 65px;
}
.img-wh-70 {
  width: 60px;
  height: 60px;
  @media (max-width: 641px) {
    width: 50px;
    height: 50px;
  }
}
.topc-abs {
  position: absolute;
  top: -35px;
  left: 0%;
}
.h-swipertestimonial {
  height: 300px !important;
  @media (max-width: 768px) and (min-width: 641px) {
    height: 280px !important;
  }
  @media (max-width: 640px) {
    height: 100% !important;
  }
}
.h-swipertestimonial8 {
  height: 300px !important;
  @media (max-width: 768px) and (min-width: 641px) {
    height: 280px !important;
  }
  @media (max-width: 640px) {
    height: 100% !important;
  }
}
.gallerybox:hover:before {
  top: 0 !important;
}
.gallerybox:before {
  content: '' !important;
  background: var(--chakra-colors-primary);
  opacity: 0.5 !important;
  height: 50% !important;
  width: 100% !important;
  left: 0px !important;
  z-index: 1 !important;
  top: -50% !important;
  transition: all 0.4s ease-in 0s;
  position: absolute;
}
.gallerybox:hover:after {
  bottom: 0 !important;
}
.gallerybox:after {
  content: '';
  background: var(--chakra-colors-primary);
  opacity: 0.5 !important;
  height: 50% !important;
  width: 100% !important;
  left: 0px !important;
  z-index: 1 !important;
  bottom: -50% !important;
  position: absolute;
  transition: all 0.4s ease-in 0s;
}
.gallery-content {
  opacity: 0;
}
.gallerybox:hover .gallery-content {
  opacity: 1;
  transition: all 0.4s ease-in 0s;
}

.border {
  border: 1px solid #eceff7 !important;
  box-shadow: none !important;
}

.box-shadow-none {
  box-shadow: none !important;
}
.plus-opacity {
  opacity: 0;
}
.gallerybox:hover .plus-opacity {
  opacity: 0.6;
  transition: all 0.5s ease-in;
}
// button effect
.btn-effect {
  background: #ececec;
  color: var(--chakra-colors-primary);
  overflow: hidden;
}
.btn-effect:hover {
  position: relative;
  z-index: 7;
  color: #fff !important;
}
.btn-effect:hover:before {
  width: 110%;
  height: 200px;
  background: var(--chakra-colors-primary);
  left: 50%;
  color: #fff !important;
  top: 50%;
  z-index: -1;
  transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
  transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
}
.btn-effect:before {
  content: '';
  position: absolute;
  width: 0px;
  color: #fff !important;
  height: 0px;
  top: 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
  border-radius: 70%;
  transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 1);
}

.gradient:hover:after {
  bottom: 0 !important;
}
.gradient:after {
  content: '';
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent);
  height: 100% !important;
  width: 100% !important;
  left: 0px !important;
  z-index: 1 !important;
  bottom: -60% !important;
  position: absolute;
  transition: all 0.2s ease-in 0s;
}
.text-gradient {
  display: none;
}
.gradient:hover .text-gradient {
  display: block;
  transition: all 0.1s ease-in 0s;
}
.h-gallery {
  height: 41.5rem !important;
  @media (max-width: 768px) and (min-width: 641px) {
    height: 21.5rem !important;
  }
  @media (max-width: 640px) {
    height: 10.5rem !important;
  }
}
.text-4rem {
  font-size: 4rem;
}
.bckdrop {
  backdrop-filter: brightness(0.5);
}

.herovideo-height {
  @media (max-width: 575px) {
    height: 250px !important;
    object-fit: fill;
  }

  @media (max-width: 768px) {
    height: 400px;
    object-fit: fill;
  }
}

.bg-darkprimary {
  background-color: #1a202c !important;
}

.border-0 {
  border: 0 !important;
}

.border-b-1px {
  border-bottom-width: 1px;
}

.border-r-1px {
  border-right-width: 1px;
}
.react-calendar {
  width: 100% !important;
  background: transparent !important;
  border: none !important;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 1rem !important;
  background: #fafafa !important;
  @media (max-width: 768px) and (min-width: 641px) {
    padding: 0.2rem !important;
  }
  @media (max-width: 640px) {
    padding: 0.2rem !important;
  }
}
.react-calendar__tile--active {
  background: var(--chakra-colors-primary) !important;
  border-radius: 5px !important;
}
.react-calendar button {
  font-size: 16px !important;
  padding: 20px !important;
  @media (max-width: 768px) and (min-width: 641px) {
    font-size: 16px !important;
    padding: 10px !important;
  }
  @media (max-width: 640px) {
    font-size: 14px !important;
    padding: 8px !important;
  }
}
.bg-glass {
  background: rgba(0, 0, 0, 0.2);
}

.fade-right {
  opacity: 0;
  transform: translate3d(100px, 0, 0);
}

.visibleright {
  opacity: 1;
  transform: translateZ(0);
  transition-property: opacity, transform;
  transition-timing-function: ease;
  transition-duration: 2s;
}
.fade-left {
  opacity: 0;
  transform: translate3d(-100px, 0, 0);
}
.visibleleft {
  opacity: 1;
  transform: translateZ(0);
  transition-property: opacity, transform;
  transition-timing-function: ease;
  transition-duration: 2s;
}

.fade-top {
  opacity: 0;
  transform: translate3d(0, -50px, 0);
}

.visibletop {
  opacity: 1;
  transform: translateZ(0);
  transition-property: opacity, transform;
  transition-timing-function: ease;
  transition-duration: 2s;
}

.fade-bottom {
  opacity: 0;
  transform: translate3d(0, 50px, 0);
}

.visiblebottom {
  opacity: 1;
  transform: translateZ(0);
  transition-property: opacity, transform;
  transition-timing-function: ease;
  transition-duration: 2s;
}

.img-dis {
  display: none;
  position: absolute !important;
  top: 0%;
  right: -50%;
}

.image-product:hover .img-dis {
  display: block;
  right: 3%;
  transition: all 0.3s ease;
}

.discount-img {
  width: 100%;
  height: 100px;
}
.border-side {
  border-left: 1px solid #f2f2f2 !important;
  border-right: 1px solid #f2f2f2 !important;
}

.fill-red:hover {
  fill: red;
  color: red;
}

.fill-prmy:hover {
  color: var(--chakra-colors-primary) !important;
}

.opct:hover {
  opacity: 0.8;
}

.bdr1-f2f2f2 {
  border: 1px solid #f2f2f2 !important;
}
.img-h-220 {
  height: 220px;
}

.img-right {
  height: 300px !important;
  margin-left: -20px;

  @media (max-width: 820px) and (min-width: 642px) {
    height: 200px !important;
  }

  @media (max-width: 640px) {
    height: 200px !important;
    margin-left: 10px;
  }
}
.bdr-left-3px {
  border-left: 3px solid var(--chakra-colors-primary);
}

.lh-60px {
  line-height: 60px;
}

.img-right-back {
  height: 300px !important;

  @media (max-width: 820px) and (min-width: 642px) {
    height: 200px !important;
  }

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

.filled-blk {
  fill: black;
}
.clr-999595 {
  color: #999595;
}
.w-45per {
  width: 45%;
}
.w-55per {
  width: 55%;
}
.h-500px {
  height: 500px;
}
.h-550px {
  height: 550px;
}
.absolute-line {
  position: absolute;
  top: 20px;
  right: -2px;
  height: 40px;
  border-right: 3px solid var(--chakra-colors-primary) !important;
  z-index: 1;
}
.absolute-line-left {
  position: absolute;
  top: 20px;
  left: -2px;
  height: 40px;
  border-right: 3px solid var(--chakra-colors-primary) !important;
  z-index: 1;
}
.absolute-line1 {
  position: absolute;
  top: 80px;
  right: -2px;
  height: 40px;
  border-right: 3px solid var(--chakra-colors-primary);
  z-index: 1;
}
.absolute-line2 {
  position: absolute;
  top: 120px;
  right: -2px;
  height: 40px;
  border-right: 3px solid var(--chakra-colors-primary) !important;
  z-index: 1;
}
.absolute-line3 {
  position: absolute;
  top: 20px;
  right: -2px;
  height: 40px;
  border-right: 3px solid var(--chakra-colors-primary) !important;
  z-index: 1;
}
.absolute-line4 {
  position: absolute;
  top: 80px;
  right: -2px;
  height: 40px;
  border-right: 3px solid var(--chakra-colors-primary);
  z-index: 1;
}
.absolute-line6 {
  position: absolute;
  top: 140px;
  right: -2px;
  height: 40px;
  border-right: 3px solid var(--chakra-colors-primary) !important;
  z-index: 1;
}
.absolute-line7 {
  position: absolute;
  top: -1px;
  right: 48%;
  width: 53px;
  border-top: 3px solid var(--chakra-colors-primary) !important;
  z-index: 1;
}
.bg-grad {
  background: linear-gradient(#0e0e0e, #1e1e1e);
}
.border-left {
  border-left: 1px solid;
  border-image: linear-gradient(
      180deg,
      #3a3a3a 16.81%,
      #fff 39.96%,
      #fff 55.38%,
      #3a3a3a 77.74%
    )
    1;
}
.border-lft {
  border-left: 1px solid;
  border-image: linear-gradient(
      180deg,
      #3a3a3a 16.81%,
      #fff 39.96%,
      #fff 55.38%,
      #3a3a3a 77.74%
    )
    1;
}
@media (max-width: 768px) and (min-width: 641px) {
  .border-left {
    border-left: 0px;
  }
  .absolute-line {
    position: absolute;
    top: 20px;
    right: -3px;
    height: 0px;
    border-right: 0px solid var(--chakra-colors-primary) !important;
    z-index: 1;
  }

  .absolute-line-left {
    position: absolute;
    top: 20px;
    left: -3px;
    height: 0px;
    border-right: 0px solid var(--chakra-colors-primary) !important;
    z-index: 1;
  }
  .absolute-line1 {
    position: absolute;
    top: 80px;
    right: -3px;
    height: 0px;
    border-right: 0px solid var(--chakra-colors-primary) !important;
    z-index: 1;
  }
  .absolute-line2 {
    position: absolute;
    top: 120px;
    right: -3px;
    height: 0px;
    border-right: 0px solid var(--chakra-colors-primary) !important;
  }
  .absolute-line3 {
    position: absolute;
    top: 20px;
    right: -2px;
    height: 40px;
    border-right: 3px solid var(--chakra-colors-primary) !important;
    z-index: 1;
  }
  .absolute-line4 {
    position: absolute;
    top: 95px;
    right: -2px;
    height: 40px;
    border-right: 3px solid var(--chakra-colors-primary) !important;
    z-index: 1;
  }
  .absolute-line6 {
    position: absolute;
    top: 180px;
    right: -2px;
    height: 40px;
    border-right: 3px solid var(--chakra-colors-primary) !important;
  }
  .absolute-line7 {
    position: absolute;
    top: -1px;
    right: 46%;
    width: 53px;
    border-top: 3px solid var(--chakra-colors-primary) !important;
    z-index: 1;
  }
  .feather-abs {
    position: absolute;
    top: 43px !important;
    right: 140px !important;
  }
}
@media (max-width: 640px) {
  .border-left {
    border-left: 0px;
  }
  .absolute-line {
    position: absolute;
    top: 20px;
    right: -3px;
    height: 0px;
    border-right: 0px solid var(--chakra-colors-primary) !important;
    z-index: 1;
  }

  .absolute-line-left {
    position: absolute;
    top: 20px;
    left: -3px;
    height: 0px;
    border-right: 0px solid var(--chakra-colors-primary) !important;
    z-index: 1;
  }

  .absolute-line1 {
    position: absolute;
    top: 80px;
    right: -3px;
    height: 0px;
    border-right: 0px solid var(--chakra-colors-primary) !important;
    z-index: 1;
  }
  .absolute-line2 {
    position: absolute;
    top: 120px;
    right: -3px;
    height: 0px;
    border-right: 0px solid var(--chakra-colors-primary) !important;
  }
  .feather-abs {
    position: absolute;
    top: 44px !important;
    right: 152px !important;
  }
  .border-lft {
    border-left: 0px solid #ffffffa1;
  }
  .absolute-line3 {
    position: absolute;
    top: 20px;
    right: -2px;
    height: 0px;
    border-right: 0px solid var(--chakra-colors-primary) !important;
    z-index: 1;
  }
  .absolute-line4 {
    position: absolute;
    top: 95px;
    right: -2px;
    height: 0px;
    border-right: 0px solid var(--chakra-colors-primary) !important;
    z-index: 1;
  }
  .absolute-line6 {
    position: absolute;
    top: 180px;
    right: -2px;
    height: 0px;
    border-right: 0px solid var(--chakra-colors-primary) !important;
  }
  .absolute-line7 {
    position: absolute;
    top: -1px;
    right: 42%;
    width: 53px;
    border-top: 3px solid var(--chakra-colors-primary) !important;
    z-index: 1;
  }
}
.border:hover {
  border: 1px solid var(--chakra-colors-primary) !important;
}

.herosection20-img {
  width: 100% !important;
  height: 400px !important;
  @media (max-width: 768px) and (min-width: 641px) {
    height: 360px !important;
  }
  @media (max-width: 640px) {
    height: 300px !important;
  }
}
.herosection21-img {
  width: 100% !important;
  height: 400px !important;
  @media (max-width: 768px) and (min-width: 641px) {
    height: 360px !important;
  }
  @media (max-width: 640px) {
    height: 300px !important;
  }
}
.about-h {
  width: 100% !important;
  height: 400px !important;
  @media (max-width: 768px) and (min-width: 641px) {
    height: 360px !important;
  }
  @media (max-width: 640px) {
    height: 100% !important;
  }
}
.about-images {
  width: 100% !important;
  height: 300px !important;
  @media (max-width: 768px) and (min-width: 641px) {
    height: 280px !important;
  }
  @media (max-width: 640px) {
    height: 200px !important;
  }
}
@media (max-width: 640px) {
  .cust-media {
    background: #2d3748 !important;
  }
}
.about2-h {
  height: 450px !important;
  @media (max-width: 768px) and (min-width: 641px) {
    height: 360px !important;
  }
  @media (max-width: 640px) {
    height: 250px !important;
  }
}
.about2-images {
  height: 450px !important;
  @media (max-width: 768px) and (min-width: 641px) {
    height: 360px !important;
  }
  @media (max-width: 640px) {
    height: 100% !important;
  }
}

.testimonial10-img {
  position: absolute;
  top: -50px;
  left: 0%;
}

.hr {
  background-color: black;
  width: 1.5px;
  height: 20px;
  opacity: 50%;
  margin: 0px 8px;
}

.absolute-line-testimonial10 {
  position: absolute;
  top: 20px;
  left: -2px;
  height: 40px;
  border-right: 3px solid var(--chakra-colors-primary) !important;
  z-index: 1;
}
.absolute-line1 {
  position: absolute;
  bottom: 20px;
  right: -2px;
  height: 40px;
  border-right: 3px solid var(--chakra-colors-primary);
  z-index: 1;
}

.shadow-primary {
  box-shadow: 4px 2px 4px var(--chakra-colors-primary);
}
.glow {
  filter: drop-shadow(10px 1px 10px var(--chakra-colors-primary));
}

.absolute-line5 {
  position: absolute;
  top: 100px;
  left: -2px;
  height: 40px;
  border-right: 3px solid var(--chakra-colors-primary) !important;
  z-index: 1;
}
.herosection22-img {
  width: 100% !important;
  height: 450px !important;
  @media (max-width: 768px) and (min-width: 641px) {
    height: 400px !important;
  }
  @media (max-width: 640px) {
    height: 100% !important;
  }
}
.heroside-img {
  width: 100% !important;
  height: 330px !important;
  @media (max-width: 768px) and (min-width: 641px) {
    height: 280px !important;
  }
  @media (max-width: 640px) {
    height: 230px !important;
  }
}
.text-grays {
  color: #ececec;
}
.border-about1 {
  border-left: 1px solid;
  border-image: linear-gradient(
      180deg,
      #3a3a3a 16.81%,
      #fff 39.96%,
      #fff 55.38%,
      #3a3a3a 77.74%
    )
    1;
}
.border-about2 {
  border-right: 1px solid;
  border-image: linear-gradient(
      180deg,
      #3a3a3a 16.81%,
      #fff 39.96%,
      #fff 55.38%,
      #3a3a3a 77.74%
    )
    1;
}
.border-line {
  position: absolute;
  bottom: 20px;
  right: -2px;
  height: 40px;
  border-right: 3px solid var(--chakra-colors-primary) !important;
  z-index: 1;
}
.border-line2 {
  position: absolute;
  top: 20px;
  left: -2px;
  height: 40px;
  border-right: 3px solid var(--chakra-colors-primary) !important;
  z-index: 1;
}
.bdr-rbot {
  border-bottom: 1px solid gray;
  border-right: 1px solid gray;
}
.bdr-bottom-d9d8d8 {
  border-bottom: 1px solid #d9d8d8;
}
.bdr-bottom {
  border-bottom: 1px solid gray;
}
.bdr-right {
  border-right: 1px solid gray;
}
.bdr-right-dcdcdc {
  border-right: 1px solid #dcdcdc;
}
.bdr-1px-dcdcdc {
  border: 1px solid #dcdcdc;
}
.bdr-ffffffa1 {
  border-image: linear-gradient(
      80deg,
      #3a3a3a 16.81%,
      #fff 39.96%,
      #fff 55.38%,
      #3a3a3a 77.74%
    )
    1;
}
// Subscriptiuonplan4

.planHover2 {
  transform: scale(1.1);
  z-index: 1;
  border: 8px solid var(--chakra-colors-primary);
  filter: drop-shadow(4px 1px 4px var(--chakra-colors-primary));
  @media (max-width: 768px) {
    transform: scale(1);
    border: 1px solid #111;
    filter: none;
  }
}

.text-glow {
  text-shadow: 0px 0px 3px red 0px 0px 5px #ccc;
}

.bg-191919 {
  background-color: #191919;
}

.bg-black {
  background-color: #000 !important;
}

.border-right {
  border-right: 1px solid #ffffffa1;
}

.planButton {
  border: 1px solid #000;
}

.planHover:hover .planButton {
  background-color: var(--chakra-colors-primary);
  border: none;
}

.plan-h {
  width: 100% !important;
  height: 800px !important;
  @media (max-width: 820px) and (min-width: 641px) {
    height: 1280px !important;
  }
  @media (max-width: 640px) {
    height: 100% !important;
  }
}
.mySwiper {
  padding: 15px !important;
  // @media (max-width: 820px) and (min-width: 641px) {
  //   height: 1280px !important;
  // }
  @media (max-width: 640px) {
    padding: 10px !important;
  }
}

.h-2rem {
  height: 2rem;
}

.select:focus-visible {
  box-shadow: none !important;
}

.clr-b8b8b8 {
  color: #b8b8b8 !important;
}

.h-700px {
  height: 700px;
}

.fsize68 {
  font-size: 68px;
}
.cust-hw-50 {
  height: 50px;
  width: 50px;
}

.w-200px {
  width: 200px;
}

.bdr-2px {
  border-radius: 2px !important;
}
.toogle {
  position: relative;
  width: 120px;
  height: 40px;
  border-radius: 20px;
  border: 0.15em solid #9f9f9f;
  background: var(--chakra-colors-primary);
  cursor: pointer;
  filter: drop-shadow(0.015em, 0.015em, 0.01em rgba(0, 0, 0, 0.3));
}
.toogle-btn::after {
  position: absolute;
  top: -5px;
  left: -10px;
  width: 50%;
  text-align: center;
  transition: all 500ms cubic-bezier(0.34, 0.78, 0.55, 1.4);
}
.toogle-checkbox {
  position: absolute;
  visibility: hidden;
}
.toogle-checkbox:checked + .toogle-btn::after {
  left: 62%;
}
.tog-true .toogle-btn::after {
  content: '';
  background: url('https://nimbuscluster.blob.core.windows.net/server01/taskmanager/Vector%20(1).png');
  background-size: contain;
  width: 50px;
  height: 50px;
}
.tog-true .toogle-checkbox:checked + .toogle-btn::after {
  content: '';
  background: url('https://nimbuscluster.blob.core.windows.net/server01/taskmanager/Vector%20(1).png');
  background-size: contain;
  width: 50px;
  height: 50px;
}
.bdr-0px {
  border-radius: 0px !important;
}
.hvr-fff:hover {
  color: #ffffff !important;
}

// &:hover {
//   color: #fff;

//   &:before {
//     width: 100%;
//   }
// }

// @media (max-width: 640px) {
//   padding: 0.75rem 1rem;
//   font-size: 0.75rem;
// }
// }

.effect-lily {
  position: relative;
  float: left;
  overflow: hidden;
  background: #3085a3;
  text-align: center;
  cursor: pointer;
}

.effect-lily img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.effect-lily .effectone {
  padding: 1em;
  color: #fff;
  font-size: 1.25em;
  backface-visibility: hidden;
  position: absolute;
  bottom: 0px;
}
@media (max-width: 768px) and (min-width: 641px) {
  .effect-lily .effectone {
    padding: 0.4em !important;
    bottom: 8%;
  }
}
@media (max-width: 640px) {
  .effect-lily .effectone {
    padding: 0.3em !important;
    bottom: 8%;
  }
}

.effect-lily img {
  max-width: none;
  width: -webkit-calc(100% + 50px);
  width: calc(100% + 50px);
  opacity: 0.7;
  transition: opacity 0.35s, transform 0.35s;
  transform: translate3d(-40px, 0, 0);
}

.effect-lily .effectone > div {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2em;
  width: 100%;
  height: 50%;
}

.effect-lily h2,
.effect-lily p {
  transform: translate3d(0, 40px, 0);
}

.effect-lily h2 {
  transition: transform 0.35s;
}

.effect-lily p {
  color: rgba(255, 255, 255, 0.8);
  opacity: 0;
  transition: opacity 0.2s, transform 0.35s;
}

.effect-lily:hover img,
.effect-lily:hover p {
  opacity: 1;
}

.effect-lily:hover img,
.effect-lily:hover h2,
.effect-lily:hover p {
  transform: translate3d(0, 0, 0);
}

.effect-lily:hover p {
  transition-delay: 0.05s;
  transition-duration: 0.35s;
}

.effect-honey {
  position: relative;
  float: left;
  overflow: hidden;
  background: #3085a3;
  text-align: center;
  cursor: pointer;
}

.effect-honey img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.effect-honey .effecttwo {
  padding: 1em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  backface-visibility: hidden;
}

.effect-honey img {
  max-width: none;
  width: -webkit-calc(100% + 50px);
  width: calc(100% + 50px);
  opacity: 0.7;
  transition: opacity 0.35s, transform 0.35s;
  transform: translate3d(-40px, 0, 0);
}

.effect-honey .effecttwo > div {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2em;
  width: 100%;
  height: 50%;
}

.effect-honey {
  background: #4a3753;
}

.effect-honey img {
  opacity: 0.9;
  transition: opacity 0.35s;
}

.effect-honey:hover img {
  opacity: 0.5;
}

.effect-honey .effecttwo::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background: #fff;
  content: '';
  transform: translate3d(0, 10px, 0);
}

.effect-honey h2 {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1em 1em;
  width: 100%;
  text-align: left;
  transform: translate3d(0, -30px, 0);
}

.effect-honey h2 i {
  font-style: normal;
  opacity: 0;
  transition: opacity 0.35s, transform 0.35s;
  transform: translate3d(0, -30px, 0);
}

.effect-honey .effecttwo::before,
.effect-honey h2 {
  transition: transform 0.35s;
}

.effect-honey:hover .effecttwo::before,
.effect-honey:hover h2,
.effect-honey:hover h2 i {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.effect-oscar {
  position: relative;
  float: left;
  overflow: hidden;
  background: #3085a3;
  text-align: center;

  cursor: pointer;
}

.effect-oscar img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.effect-oscar .effectthird {
  padding: 2em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  backface-visibility: hidden;
}

.effect-oscar {
  background: -webkit-linear-gradient(
    45deg,
    #22682a 0%,
    #9b4a1b 40%,
    #3a342a 100%
  );

  background: linear-gradient(45deg, #22682a 0%, #9b4a1b 40%, #3a342a 100%);
}

.effect-oscar img {
  opacity: 0.9;
  transition: opacity 0.35s;
}

.effect-oscar .effectthird {
  padding: 3em;
  background-color: rgba(58, 52, 42, 0.7);
  transition: background-color 0.35s;
  @media (max-width: 768px) and (min-width: 641px) {
    padding: 1em !important;
  }
  @media (max-width: 640px) {
    padding: 1em !important;
  }
}

.effect-oscar .effectthird::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 1px solid #fff;
  content: '';
  @media (max-width: 768px) and (min-width: 641px) {
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
  }
  @media (max-width: 640px) {
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
  }
}

.effect-oscar h2 {
  margin: 20% 0 10px 0;
  transition: transform 0.35s;
  transform: translate3d(0, 100%, 0);
  @media (max-width: 768px) and (min-width: 641px) {
    margin: 8% 0 10px 0;
  }
}

@media (max-width: 640px) {
  .effect-oscar h2 {
    margin: 5% 0 10px 0;
  }
}

.effect-oscar .effectthird::before,
.effect-oscar p {
  opacity: 0;
  transition: opacity 0.35s, transform 0.35s;
  transform: scale(0);
}

.effect-oscar:hover h2 {
  transform: translate3d(0, 0, 0);
}

.effect-oscar:hover .effectthird::before,
.effect-oscar:hover p {
  opacity: 1;
  transform: scale(1);
}

.effectthird {
  position: absolute;
  top: 0px;
}

.effect-oscar:hover .effectthird {
  background-color: rgba(58, 52, 42, 0);
}

.effect-oscar:hover img {
  opacity: 0.4;
}

.effect-layla {
  position: relative;
  float: left;
  overflow: hidden;
  background: #3085a3;
  text-align: center;
  cursor: pointer;
}

.effect-layla img {
  position: relative;
  display: block;
  opacity: 0.8;
}

.effect-layla .effectforth {
  padding: 2em;
  color: #fff;
  font-size: 1.25em;
  backface-visibility: hidden;
}

.effect-layla {
  background: #18a367;
}

.effect-layla .effectforth {
  position: absolute;
  top: 0px;
  padding: 3em;
  @media (max-width: 768px) and (min-width: 641px) {
    padding: 1em;
  }
  @media (max-width: 640px) {
    padding: 1em;
  }
}

.effect-layla .effectforth::before,
.effect-layla .effectforth::after {
  position: absolute;
  content: '';
  opacity: 0;
}

.effect-layla .effectforth::before {
  top: 50px;
  right: 30px;
  bottom: 50px;
  left: 30px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform: scale(0, 1);
  transform-origin: 0 0;
  @media (max-width: 768px) and (min-width: 641px) {
    top: 25px;
    right: 15px;
    bottom: 25px;
    left: 15px;
  }
  @media (max-width: 640px) {
    top: 30px;
    right: 10px;
    bottom: 30px;
    left: 10px;
  }
}

.effect-layla .effectforth::after {
  top: 30px;
  right: 50px;
  bottom: 30px;
  left: 50px;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  transform: scale(1, 0);
  transform-origin: 100% 0;
  @media (max-width: 768px) and (min-width: 641px) {
    top: 15px;
    right: 25px;
    bottom: 15px;
    left: 25px;
  }
  @media (max-width: 640px) {
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
  }
}

.effect-layla h2 {
  padding-top: 26%;
  transition: transform 0.35s;
}

.effect-layla p {
  padding: 0.5em 2em;
  text-transform: none;
  opacity: 0;
  transform: translate3d(0, -10px, 0);
}

.effect-layla img,
.effect-layla h2 {
  transform: translate3d(0, 0px, 0);
}

.effect-layla img,
.effect-layla .effectforth::before,
.effect-layla .effectforth::after,
.effect-layla p {
  transition: opacity 0.35s, transform 0.35s;
}

.effect-layla:hover img {
  opacity: 0.7;
  transform: translate3d(0, 0, 0);
}

.effect-layla:hover .effectforth::before,
.effect-layla:hover .effectforth::after {
  opacity: 1;
  transform: scale(1);
}

.effect-layla:hover h2,
.effect-layla:hover p {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.effect-layla:hover .effectforth::after,
.effect-layla:hover h2,
.effect-layla:hover p,
.effect-layla:hover img {
  transition-delay: 0.15s;
}

.effect-chico {
  position: relative;
  float: left;
  overflow: hidden;
  background: #3085a3;
  text-align: center;
  cursor: pointer;
}

.effect-chico img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.effect-chico .effectfifth {
  padding: 2em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  backface-visibility: hidden;
}

.effect-chico img {
  transition: opacity 0.35s, transform 0.35s;
  transform: scale(1.12);
}

.effect-chico:hover img {
  opacity: 0.5;
  transform: scale(1);
}

.effect-chico .effectfifth {
  padding: 3em;
  position: absolute;
  top: 0px;
  @media (max-width: 768px) and (min-width: 641px) {
    padding: 1em;
  }
  @media (max-width: 640px) {
    padding: 1em;
  }
}

.effect-chico .effectfifth::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 1px solid #fff;
  content: '';
  transform: scale(1.1);
  @media (max-width: 768px) and (min-width: 641px) {
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
  }
  @media (max-width: 640px) {
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
  }
}

.effect-chico .effectfifth::before,
.effect-chico p {
  opacity: 0;
  transition: opacity 0.35s, transform 0.35s;
}

.effect-chico h2 {
  padding: 20% 0 20px 0;
  @media (max-width: 768px) and (min-width: 641px) {
    padding: 10% 0 10px 0;
  }
}

@media (max-width: 640px) {
  .effect-chico h2 {
    padding: 8% 0 10px 0;
  }
}

.effect-chico p {
  margin: 0 auto;
  max-width: 200px;
  transform: scale(1.5);
}

.effect-chico:hover .effectfifth::before,
.effect-chico:hover p {
  opacity: 1;
  transform: scale(1);
}

.effect-sarah {
  position: relative;
  float: left;
  overflow: hidden;
  background: #3085a3;
  text-align: center;
  cursor: pointer;
}

.effect-sarah img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.effect-sarah .effectsixth {
  padding: 2em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  backface-visibility: hidden;
  position: absolute;
  top: 0px;
  @media (max-width: 768px) and (min-width: 641px) {
    padding: 1em;
  }
  @media (max-width: 640px) {
    padding: 1em;
  }
}

.effect-sarah {
  background: #42b078;
}

.effect-sarah img {
  max-width: none;
  width: -webkit-calc(100% + 20px);
  width: calc(100% + 20px);
  transition: opacity 0.35s, transform 0.35s;
  transform: translate3d(-10px, 0, 0);
  backface-visibility: hidden;
}

.effect-sarah:hover img {
  opacity: 0.4;
  transform: translate3d(0, 0, 0);
}

.effect-sarah .effectsixth {
  text-align: left;
}

.effect-sarah h2 {
  position: relative;
  overflow: hidden;
  padding: 0.5em 0;
}

.effect-sarah h2::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: #fff;
  content: '';
  transition: transform 0.35s;
  transform: translate3d(-100%, 0, 0);
}

.effect-sarah:hover h2::after {
  transform: translate3d(0, 0, 0);
}

.effect-sarah p {
  padding: 1em 0;
  opacity: 0;
  transition: opacity 0.35s, transform 0.35s;
  transform: translate3d(100%, 0, 0);
}

.effect-sarah:hover p {
  opacity: 1;

  -webkit-transform: translate3d(0, 0, 0);

  transform: translate3d(0, 0, 0);
}

.effect-roxy {
  position: relative;
  float: left;
  overflow: hidden;
  background: #3085a3;
  text-align: center;
  cursor: pointer;
}

.effect-roxy img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.effect-roxy .effectseven {
  padding: 2em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  backface-visibility: hidden;
  position: absolute;
  top: 0px;
}

.effect-roxy {
  background: -webkit-linear-gradient(45deg, #ff89e9 0%, #05abe0 100%);
  background: linear-gradient(45deg, #ff89e9 0%, #05abe0 100%);
}

.effect-roxy img {
  max-width: none;
  width: -webkit-calc(100% + 60px);
  width: calc(100% + 60px);
  transition: opacity 0.35s, transform 0.35s;
  transform: translate3d(-50px, 0, 0);
}

.effect-roxy .effectseven::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 1px solid #fff;
  content: '';
  opacity: 0;
  transition: opacity 0.35s, transform 0.35s;
  transform: translate3d(-20px, 0, 0);
  @media (max-width: 768px) and (min-width: 641px) {
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
  }
  @media (max-width: 640px) {
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
  }
}

.effect-roxy .effectseven {
  padding: 3em;
  text-align: left;

  @media (max-width: 768px) and (min-width: 641px) {
    padding: 1em;
  }
  @media (max-width: 640px) {
    padding: 1em;
  }
}

.effect-roxy h2 {
  padding: 30% 0 10px 0;
}

.effect-roxy p {
  opacity: 0;
  transition: opacity 0.35s, transform 0.35s;
  transform: translate3d(-10px, 0, 0);
}

.effect-roxy:hover img {
  opacity: 0.7;
  transform: translate3d(0, 0, 0);
}

.effect-roxy:hover .effectseven::before,
.effect-roxy:hover p {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.effect-bubba {
  position: relative;
  float: left;
  overflow: hidden;
  background: #3085a3;
  text-align: center;
  cursor: pointer;
}

.effect-bubba img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.effect-bubba .effecteight {
  padding: 2em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  backface-visibility: hidden;
  position: absolute;
  top: 0px;
  @media (max-width: 768px) and (min-width: 641px) {
    padding: 1em;
  }
  @media (max-width: 640px) {
    padding: 1em;
  }
}

.effect-bubba {
  background: #9e5406;
}

.effect-bubba img {
  opacity: 0.7;
  transition: opacity 0.35s;
}

.effect-bubba:hover img {
  opacity: 0.4;
}

.effect-bubba .effecteight::before,
.effect-bubba .effecteight::after {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  content: '';
  opacity: 0;
  transition: opacity 0.35s, transform 0.35s;
  @media (max-width: 768px) and (min-width: 641px) {
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
  }
  @media (max-width: 640px) {
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
  }
}

.effect-bubba .effecteight::before {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform: scale(0, 1);
}

.effect-bubba .effecteight::after {
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  transform: scale(1, 0);
}

.effect-bubba h2 {
  padding-top: 20%;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, -20px, 0);
  transform: translate3d(0, -20px, 0);
  @media (max-width: 768px) and (min-width: 641px) {
    padding-top: 10%;
  }
}

.effect-bubba p {
  padding: 20px 2.5em;
  opacity: 0;
  transition: opacity 0.35s, transform 0.35s;
  transform: translate3d(0, 20px, 0);
  @media (max-width: 768px) and (min-width: 641px) {
    padding: 10px 2.5em;
  }
  @media (max-width: 640px) {
    padding: 10px 1em;
  }
}

.effect-bubba:hover .effecteight::before,
.effect-bubba:hover .effecteight::after {
  opacity: 1;
  transform: scale(1);
}

.effect-bubba:hover h2,
.effect-bubba:hover p {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.effect-marley {
  position: relative;
  float: left;
  overflow: hidden;
  background: #3085a3;
  text-align: center;
  cursor: pointer;
}

.effect-marley img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.effect-marley .effectnine {
  padding: 2em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  backface-visibility: hidden;
  position: absolute;
  top: 0px;
  @media (max-width: 768px) and (min-width: 641px) {
    padding: 1em;
  }
}

.effect-marley .effectnine {
  text-align: right;
}

.effect-marley h2,
.effect-marley p {
  position: absolute;
  right: 30px;
  left: 30px;
  padding: 10px 0;
}

.effect-marley p {
  bottom: 30px;
  line-height: 1.5;
  transform: translate3d(0, 100%, 0);
}

.effect-marley h2 {
  top: 30px;
  transition: transform 0.35s;
  transform: translate3d(0, 20px, 0);
}

.effect-marley:hover h2 {
  transform: translate3d(0, 0, 0);
}

.effect-marley h2::after {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 4px;
  background: #fff;
  content: '';
  transform: translate3d(0, 40px, 0);
}

.effect-marley h2::after,
.effect-marley p {
  opacity: 0;
  transition: opacity 0.35s, transform 0.35s;
}

.effect-marley:hover h2::after,
.effect-marley:hover p {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.effect-zoe {
  position: relative;
  float: left;
  overflow: hidden;
  background: #3085a3;
  text-align: center;
  cursor: pointer;
}

.effect-zoe img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.effect-zoe .effectten {
  padding: 2em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  backface-visibility: hidden;
  position: absolute;
  top: 0px;
  @media (max-width: 768px) and (min-width: 641px) {
    padding: 1em;
  }
}

.effect-zoe .effectten {
  top: auto;
  bottom: 0;
  padding: 1em;
  height: 3.75em;
  background: #fff;
  color: #3c4a50;
  transition: transform 0.35s;
  transform: translate3d(0, 100%, 0);
  @media (max-width: 768px) and (min-width: 641px) {
    padding: 0.5em;
    height: 2.75em;
  }
  @media (max-width: 640px) {
    padding: 0.5em;
    height: 2.25em;
  }
}

.effect-zoe h2 {
  float: left;
}

.effect-zoe p.icon-links a {
  float: right;
  color: #3c4a50;
  font-size: 1.4em;
}

.effect-zoe:hover p.icon-links a:hover,
.effect-zoe:hover p.icon-links a:focus {
  color: #252d31;
}

.effect-zoe p.description {
  position: absolute;
  bottom: 8em;
  padding: 2em;
  color: #fff;
  text-transform: none;
  font-size: 16px;
  opacity: 0;
  transition: opacity 0.35s;
  @media (max-width: 768px) and (min-width: 641px) {
    bottom: 6em;
    right: 0px;
    padding: 0em;
    font-size: 14px;
    width: 100%;
  }
  @media (max-width: 640px) {
    bottom: 6em;
    right: 0px;
  }
}

.absolute-mid {
  position: absolute;
  top: -60px;
  left: 37px;
}

@media (max-width: 640px) {
  .effect-zoe p.description {
    bottom: 5em;
    font-size: 12px;
  }
}

.effect-zoe h2,
.effect-zoe p.icon-links a {
  transition: transform 0.35s;
  transform: translate3d(0, 200%, 0);
}

.effect-zoe p.icon-links a span::before {
  display: inline-block;
  padding: 8px 10px;
  font-family: 'feathericons';
  speak: none;
}

.icon-eye::before {
  content: '\e000';
}

.icon-paper-clip::before {
  content: '\e001';
}

.icon-heart::before {
  content: '\e024';
}

.effect-zoe h2 {
  display: inline-block;
}

.effect-zoe:hover p.description {
  opacity: 1;
}

.effect-zoe:hover .effectten,
.effect-zoe:hover h2,
.effect-zoe:hover p.icon-links a {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.effect-zoe:hover h2 {
  transition-delay: 0.05s;
}

.effect-zoe:hover p.icon-links a:nth-child(3) {
  transition-delay: 0.1s;
}

.effect-zoe:hover p.icon-links a:nth-child(2) {
  -webkit-transition-delay: 0.15s;

  transition-delay: 0.15s;
}

.effect-zoe:hover p.icon-links a:first-child {
  transition-delay: 0.2s;
}

.effect-dexter {
  position: relative;
  float: left;
  overflow: hidden;
  background: #3085a3;
  text-align: center;
  cursor: pointer;
}

.effect-dexter img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.effect-dexter .effecteleven {
  padding: 2em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  backface-visibility: hidden;
  position: absolute;
  top: 0px;
}

.effect-dexter {
  background: -webkit-linear-gradient(
    top,
    rgba(37, 141, 200, 1) 0%,
    rgba(104, 60, 19, 1) 100%
  );

  background: linear-gradient(
    to bottom,
    rgba(37, 141, 200, 1) 0%,
    rgba(104, 60, 19, 1) 100%
  );
}

.effect-dexter img {
  transition: opacity 0.35s;
}

.effect-dexter:hover img {
  opacity: 0.4;
}

.effect-dexter .effecteleven::after {
  position: absolute;
  right: 30px;
  bottom: 30px;
  left: 30px;
  height: -webkit-calc(50% - 30px);
  height: calc(50% - 30px);
  border: 7px solid #fff;
  content: '';
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  @media (max-width: 768px) and (min-width: 641px) {
    right: 10px;
    bottom: 39px;
    left: 10px;
    height: 80px;
  }
  @media (max-width: 640px) {
    right: 10px;
    bottom: 22px;
    left: 10px;
    height: 90px;
  }
}

.effect-dexter:hover .effecteleven::after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.effect-dexter .effecteleven {
  padding: 3em;
  text-align: left;
  @media (max-width: 768px) and (min-width: 641px) {
    padding: 1em;
  }
  @media (max-width: 640px) {
    padding: 1em;
  }
}

.effect-dexter p {
  position: absolute;
  right: 60px;
  bottom: 60px;
  left: 60px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, -100px, 0);
  transform: translate3d(0, -100px, 0);
  @media (max-width: 768px) and (min-width: 641px) {
    right: 30px;
    bottom: 60px;
    left: 30px;
  }
  @media (max-width: 640px) {
    right: 20px;
    bottom: 40px;
    left: 20px;
  }
}

.effect-dexter:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.gallery6Image {
  width: 500px;
  height: 300px;
  @media (max-width: 640px) {
    height: 250px !important;
  }
}
.img-h-300 {
  height: 300px;
}
.hvr-scale:hover {
  transform: scale(1.1);
  transition-duration: 1s;
}

.drop-shad {
  filter: drop-shadow(0 0 0.25 #f4f5f6) !important;
}

.border-1px-vertical {
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}

.category-angle {
  bottom: -10px;
  left: -10px;
}

.borderRadius-3sides {
  border-radius: 8px 8px 8px 0px;
}

.category-angle-left {
  width: 0px;
  height: 0px;
  border-top: 0px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid var(--chakra-colors-primary);
}

.category-angle-left-dark {
  width: 0px;
  height: 0px;
  border-top: 0px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #2d3748;
}

.border-left-1px {
  border-left: 1px solid gray;
}

.bg-f8f8f8 {
  background-color: #f8f8f8;
}

.img-ratio {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.text-b2b2b2 {
  color: #b2b2b2;
}

.img-h-170 {
  height: 170px;
}
.bdr-bottom-line {
  border-bottom: 2px solid gray;
  width: 300px;
}
.gallery7-img {
  height: 450px;
  @media (max-width: 768px) and (min-width: 641px) {
    height: 300px;
  }
  @media (max-width: 640px) {
    height: 190px;
  }
}
.gallerysecond7-img {
  height: 190px;
  @media (max-width: 768px) and (min-width: 641px) {
    height: 130px;
  }
  @media (max-width: 640px) {
    height: 190px;
  }
}
.gallerythird7-img {
  height: 250px;
  @media (max-width: 768px) and (min-width: 641px) {
    height: 160px;
  }
  @media (max-width: 640px) {
    height: 190px;
  }
}

.bgcheck {
  background: var(--chakra-colors-primary);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #fff;
}

.bgcheckDark {
  background: rgba(255, 255, 255, 0.3);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #fff;
}

.border-plan {
  border-bottom: 2px dashed var(--chakra-colors-primary);
}

.border-plan-dark {
  border-bottom: 2px dashed rgba(255, 255, 255, 0.3);
}

.bghoverplan:hover {
  background: var(--chakra-colors-primary);
  margin-top: -40px;
  transition: 0.5s;
  @media (max-width: 640px) {
    margin-top: 0px;
  }
}
.bghoverplanDark:hover {
  background: rgba(255, 255, 255, 0.3);
  margin-top: -40px;
  transition: 0.5s;
  @media (max-width: 640px) {
    margin-top: 0px;
  }
}
.bghoverplan:hover .border-plan {
  border-bottom: 2px dashed #fff;
}
.bghoverplan:hover .bgcheck {
  background: #fff;
  color: var(--chakra-colors-primary);
}
.bghoverplan:hover .plancolor {
  color: #fff;
}
.btn-mdsize {
  padding: 12px 30px !important;
  height: inherit !important;
  font-weight: 500 !important;
}

.h-350px {
  height: 350px;
}

.tabnone {
  background: transparent;
}
.tabchecked {
  background: var(--chakra-colors-primary);
  color: #fff;
}
.absl-rad {
  position: absolute !important;
  top: -30px;
  left: 20px;
}
.bg-e9e7e7 {
  background: #e9e7e7;
}
.rad-rev {
  border-top-right-radius: 40px;
  border-bottom-left-radius: 40px;
}
.rad-rev-24px {
  border-top-right-radius: 24px;
  border-bottom-left-radius: 24px;
}
.rounded-4xl {
  border-radius: 2.5rem;
}
.h-600px {
  height: 600px;
}
.h-work {
  height: 700px !important;
}
.col-ffc1 {
  color: #ffc148 !important;
}
.radius-card {
  border-radius: 10px 0 0 10px !important;
}
.bg-3d3d3d {
  background: #3d3d3d;
}
.bdr-gray-2px {
  border: 2px solid gray;
}
.bdr-gray-1px {
  border: 1px solid gray !important;
}
.bdr-d5d2d2-1px {
  border: 1px solid #d5d2d2;
}
.bg-faf9ff {
  background: #faf9ff !important;
}
.bdr-btm {
  border-bottom: 1px solid #cbcbcb;
}
.bg-e9e9e9 {
  background: #e9e9e9;
}
.overflow-swipe {
  overflow-x: auto;
  display: -webkit-box !important;
}
.py-4rem {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.accord-bdr {
  border-bottom: 1px solid #9c9c9c !important;
  border-top: 0px !important;
}
.bdr-gray-2px {
  border: 2px solid gray;
}
.testimonial_padd {
  padding: 5px !important;
}
.bg-dark {
  background: #111;
}
.subscription-radius {
  border-radius: 0px 40px 0px 40px;
  border: 1px solid #fff;
}
.subscription-radius:hover {
  background: rgba(255, 255, 255, 0.3);
  border: none !important;
}
.subscription-radius:hover .sub6-hover {
  color: #fff;
}
.subscription-radius:hover .subtn-hover {
  background: #fff;
  color: var(--chakra-colors-primary);
}
.testimonial_padd {
  padding: 5px !important;
}
.bg-lightShadownone {
  background: #f4f5f6 !important;
  box-shadow: none !important;
}

.bg-B2F5EA {
  background-color: #b2f5ea !important;
}

.bradius-card-top {
  border-radius: 0.375rem 0.375rem 0 0;
}

// .gallery8 .swiper-slide.swiper-slide-visible.swiper-slide-active{
//   margin-left: -22% !important;
//   margin-right: 18% !important;
// }
// .gallery8 .swiper-slide.swiper-slide-visible.swiper-slide-next{
//   margin-left: 9% !important;
//   margin-right: 14% !important;
// }
// .gallery8 .swiper-slide.swiper-slide-prev{
//   margin-left: -28% !important;
//   margin-right: 16% !important;
// }
// .gallery8 .swiper-slide.swiper-slide-visible{
//   margin-left: 8% !important;
//   margin-right: 6% !important;
// }

// .gallery8 .swiper-slide.swiper-slide-visible.swiper-slide-next{
//   margin-left: 19% !important;
//   margin-right: 14% !important;
// }

.h-480px {
  height: 480px;
}
.rad-lbot-4rem {
  border-bottom-left-radius: 4rem;
}
.rad-rbot-4rem {
  border-bottom-right-radius: 4rem;
}
.rad-ltop-4rem {
  border-top-left-radius: 4rem;
}
.rad-rtop-4rem {
  border-top-right-radius: 4rem;
}
.bradius-card-bottom {
  border-radius: 0 0 0.375rem 0.375rem;
}

.card-badge-green {
  color: #409a61 !important ;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.card-badge-blue {
  color: #67bbc0 !important ;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.imgtwo {
  background: white !important;
  background-image: linear-gradient(45deg, #ccc 25%, tranparent 25%),
    linear-gradient(-45deg, #ccc 25%, tranparent 25%),
    linear-gradient(45deg, #ccc 75%, tranparent 75%),
    linear-gradient(-45deg, #ccc 75%, tranparent 75%) !important;
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}
.flex-grow {
  flex-grow: 1 !important;
}
.absl-transform {
  position: absolute;
  bottom: 6%;
  left: 50%;
  -webkit-transform-origin-x: -50%;
  transform: translate(-50%);
}
.absl-team12 {
  position: absolute;
  bottom: 4%;
  left: 50%;
  width: 90%;
  transform: translate(-50%);
}
.row-span-2-3 {
  grid-row: span 2/3;
}

.gallery8 .swiper-slide.swiper-slide-visible.swiper-slide-next {
  transform: scale(1.2) !important;
  @media (max-width: 640px) {
    transform: scale(1) !important;
  }
}
.gallery8 .swiper-slide.swiper-slide-visible {
  transform: scale(0.8) !important;
  @media (max-width: 640px) {
    transform: scale(1) !important;
  }
}
.gallery8 .swiper-slide.swiper-slide-visible.swiper-slide-active {
  transform: scale(0.8) !important;
  @media (max-width: 640px) {
    transform: scale(1) !important;
  }
}
.gallery8 .swiper-slide.swiper-slide-prev {
  transform: scale(0.8) !important;
  @media (max-width: 640px) {
    transform: scale(1) !important;
  }
}
.gallery-8-img {
  height: 400px;
  @media (max-width: 768px) and (min-width: 641px) {
    height: 200px;
  }
  @media (max-width: 640px) {
    height: 190px;
  }
}
.gallery8 .swiper-wrapper {
  @media (max-width: 768px) and (min-width: 641px) {
    margin-left: -12px !important;
  }
}

.minusMargin-swiper {
  margin-top: -8rem;

  @media (max-width: 640px) {
    margin-top: -4rem;
  }
}

.newSwiper .image-swiper-button-prev {
  top: auto !important;
  bottom: 0px;
  left: 33%;

  @media (max-width: 820px) and (min-width: 641px) {
    top: auto !important;
    bottom: -4px;
    left: 13%;
  }

  @media (max-width: 640px) {
    top: auto !important;
    bottom: -4px;
    left: 13%;
  }
}

.newSwiper .image-swiper-button-next {
  top: auto !important;
  bottom: 0px;
  right: 55%;

  @media (max-width: 820px) and (min-width: 641px) {
    top: auto !important;
    bottom: -4px;
    left: 23%;
  }

  @media (max-width: 640px) {
    top: auto !important;
    bottom: -4px;
    right: 68%;
  }
}
.bg-dae9d7 {
  background: #dae9d7;
}
.bdr-gray-2px {
  border: 2px solid gray;
}
.tranparent-hero-title {
  font-size: 170px;
  font-weight: bolder;
  color: rgba(247, 251, 255, 0.3);
  background: transparent;
  line-height: 120px;
  letter-spacing: 16px;

  @media (max-width: 820px) and (min-width: 641px) {
    line-height: 120px;
    font-size: 120px;
    letter-spacing: 12px;
  }

  @media (max-width: 640px) {
    top: auto !important;
    bottom: -4px;
    right: -2%;
    font-size: 70px;
    line-height: 70px;
    letter-spacing: 12px;
  }
}

.letter-spacing8px {
  letter-spacing: 8px;
}

.filter-b6 {
  filter: brightness(0.6);
}
.bdr-d9d8d8-2px {
  border: 2px solid #d9d8d8;
}

.absl6-text {
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.absl7-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.absl6-cards {
  position: absolute;
  bottom: -75px;
  left: 50%;
  -webkit-transform-origin-x: -50%;
  transform: translate(-50%);
}
.bdr-blue {
  border: 2px solid var(--chakra-colors-secondary) !important;
}
.mar-test12 {
  margin: auto -110px;
}
.bdr-test12 {
  border-left: 1px solid white;
}
.absl-test12 {
  @media (min-width: 576px) {
    position: absolute;
    top: 16%;
    right: 20%;
  }
}
.gal9-image:hover .gal9 {
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
}
.fill-icon {
  // color: var(--chakra-colors-primary) !important;
  fill: var(--chakra-colors-primary) !important;
}
.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.bdr-1px-fff {
  border: 1px solid #fff;
}
.h-650px {
  height: 650px;
}
.gallery11-text {
  background: #0000004d !important;
  transition: 0.6s all ease;
}
@media (max-width: 768px) and (min-width: 641px) {
  .md-max-w {
    max-width: 80% !important;
  }
}
@media (max-width: 640px) {
  .sm-max-w {
    max-width: 80% !important;
  }
}
.bg-efeaea {
  background: #efeaea;
}
.css-161pkch > option .custride {
  background-color: #a2a2a2 !important;
}

// feature-card

.feature-card22 {
  background-color: hsl(220, 6%, 10%);
  padding: 120px 30px 30px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.feature-card22:nth-child(1) {
  --color: #aa96da;
}

.feature-card22:nth-child(2) {
  --color: #c5fad5;
}

.feature-card22:nth-child(3) {
  --color: #ffbf69;
}

.feature-card22::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--color);
  z-index: -1;
  clip-path: circle(40px at 70px 70px);
  transition: clip-path 1s ease;
}

.feature-card22:hover::before {
  clip-path: circle(100%);
}

.feature-card22 span {
  position: absolute;
  left: 0;
  top: 0;
  height: 80px;
  width: 80px;
  font-size: 50px;
  font-weight: bold;
  transform: translate(30px, 30px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: hsl(0, 0%, 0%);
  transition: transform 1s ease;
}

.feature-card22:hover span {
  transform: translate(0, 30px);
}

.feature-card22 h2 {
  font-size: 26px;
  color: hsl(0, 0%, 100%);
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 10px;
  line-height: 1.3;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.feature-card22 p {
  color: hsl(0, 0%, 85%);
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.feature-card22 a {
  display: inline-block;
  text-transform: capitalize;
  color: hsl(0, 0%, 100%);
  margin-top: 20px;
  font-weight: 500;
  text-decoration: underline;
}

.feature-card22 a,
.feature-card22 h2,
.feature-card22 p {
  transition: color 1s ease;
}

.feature-card22:hover a,
.feature-card22:hover h2,
.feature-card22:hover p {
  color: hsl(0, 0%, 0%);
}

// Loading

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f4f5f6;
  z-index: 9999;
}

.loading-text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  width: 100%;
  height: 100px;
  line-height: 100px;
}

.loading-text span {
  display: inline-block;
  margin: 0 5px;
  color: var(--chakra-colors-primary);
  font-size: 24px;
  font-weight: 600;
}

.loading-text span:nth-child(1) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 0s infinite linear alternate;
  animation: blur-text 1.5s 0s infinite linear alternate;
}

.loading-text span:nth-child(2) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 0.2s infinite linear alternate;
  animation: blur-text 1.5s 0.2s infinite linear alternate;
}

.loading-text span:nth-child(3) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 0.4s infinite linear alternate;
  animation: blur-text 1.5s 0.4s infinite linear alternate;
}

.loading-text span:nth-child(4) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 0.6s infinite linear alternate;
  animation: blur-text 1.5s 0.6s infinite linear alternate;
}

.loading-text span:nth-child(5) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 0.8s infinite linear alternate;
  animation: blur-text 1.5s 0.8s infinite linear alternate;
}

.loading-text span:nth-child(6) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 1s infinite linear alternate;
  animation: blur-text 1.5s 1s infinite linear alternate;
}

.loading-text span:nth-child(7) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 1.2s infinite linear alternate;
  animation: blur-text 1.5s 1.2s infinite linear alternate;
}

@-webkit-keyframes blur-text {
  0% {
    filter: blur(0px);
  }
  100% {
    filter: blur(4px);
  }
}

@keyframes blur-text {
  0% {
    filter: blur(0px);
  }
  100% {
    filter: blur(4px);
  }
}

// Timeline
.featurecardhover {
  display: none;
}
.feature23:hover .featurecardhover {
  display: block;
}
.feature23:hover .features {
  background: var(--chakra-colors-primary) !important;
  opacity: 0.7 !important;
}
.pad-none {
  padding: 0px !important;
}
.minus-absolute {
  margin-bottom: -5% !important;
}

.bg-e7ddff {
  background-color: #e7ddff;
}
.img-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
}

.img-container {
  position: relative;
  display: inline-block;
}
.footer-icon {
  border: 1px solid #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-icon:hover {
  background-color: var(--chakra-colors-secondary);
  color: white;
  border: none;
}

.anchor-li:hover {
  color: var(--chakra-colors-secondary);
}

.lheight48 {
  line-height: 48px;
}

.h-800px {
  height: 800px;
}
.card25:hover {
  background-color: #7c7c7c;
  color: white;
  border: 0px !important;
}

.onHoverShadow:hover {
  box-shadow: rgba(0, 0, 0, 0.08) 2px 2px 18px 0px;
}

.onHoverShadow:hover p {
  font-weight: 600;
}

.bg-31313d {
  background-color: #31313d;
}

.clr-b9b9b9 {
  color: #b9b9b9 !important;
}

.card-swiper-button-previous {
  position: absolute !important;
  z-index: 9999 !important;
  left: 5px !important;
  top: 40% !important;
  color: var(--qtheme-color-secondary) !important;

  @media (max-width: 820px) and (min-width: 641px) {
    left: -25px !important;
  }

  @media (max-width: 640px) {
    left: -25px !important;
  }
}

.card-swiper-button-next {
  position: absolute !important;
  z-index: 9999 !important;
  right: 5px !important;
  top: 40% !important;
  color: var(--qtheme-color-secondary) !important;

  @media (max-width: 820px) and (min-width: 641px) {
    right: -10px !important;
  }

  @media (max-width: 640px) {
    right: -10px !important;
  }
}

.bg-272b3f {
  background-color: #272b3f;
}

.verticalLine {
  width: 5px;
  margin-left: 2rem;
  margin-right: 2rem;
  border-right: 1px solid rgba(255, 255, 255, 1);

  @media (max-width: 640px) {
    display: none;
  }
}
.verticalLine1 {
  width: 5px;
  margin-left: 2rem;
  margin-right: 2rem;
  border-right: 2px solid rgba(255, 255, 255, 1);

  @media (max-width: 640px) {
    display: none;
  }
}

.bg-444853 {
  background-color: #444853;
}
.feature26 .featurehover26 {
  background: #fff;
  color: #111;
}
.feature26:hover .featurehover26 {
  background: var(--chakra-colors-primary);
  color: #fff;
}

.feature26absolute {
  position: absolute;
  bottom: -4%;
  left: 0px;
  border-bottom: 3px solid #ececec;
  width: 100%;
}
.feature26:hover .feature26absolute {
  border-bottom: 3px solid var(--chakra-colors-primary);
}
@media (max-width: 640px) {
  .sm-bg-fas {
    background: #fafafa !important;
  }
}
.minus-margintop {
  margin-bottom: -3%;
  @media (max-width: 768px) and (min-width: 641px) {
    margin-bottom: -6%;
  }
  @media (max-width: 640px) {
    margin-bottom: 0%;
  }
}
.herosection29 {
  color: rgb(201, 193, 193);
  border: 1px solid #fafafa !important;
}
.herosection29:hover {
  background: var(--chakra-colors-primary);
  color: #fff;
  border: none !important;
}
.feature27absolute {
  position: absolute;
  bottom: 0%;
  left: 0px;
  width: 100%;
}
.border-ec {
  border: 1px solid #222;
}
.border-black {
  border: 1px solid #ececec;
}
.tab-width {
  width: 140px !important;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) and (min-width: 641px) {
    width: 100px !important;
  }
  @media (max-width: 640px) {
    width: 100% !important;
    padding-left: 14px;
    padding-right: 14px;
  }
}
.rotate-dot {
  transform: rotate(50deg);
}
.border-dash {
  border-bottom: 2px dashed #111 !important;
}
.border-bottom {
  border-bottom: 1px solid #111 !important;
}
.btn-translate {
  transform: translate(-6px, -6px);
}
.btn-translate:hover {
  transform: translate(0px, 0px);
  background: var(--chakra-colors-primary) !important;
  color: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}
.hr-layout2 {
  border-top-width: 3px;
  border-color: var(--chakra-colors-primary);
  margin: auto;
  width: 75px;
}

//// header 4 CSSS

header {
  background-color: var(--chakra-colors-primary);
  padding: 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5), 0 6px 6px rgba (0, 0, 0, 0, 0.6);
}

header nav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.logo a {
  text-decoration: none;
  font-size: 26px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 800;
}

.bartoggle,
#menudrop {
  display: none;
}

.NavMenu {
  flex: 10;
  list-style: none;
  position: relative;
  display: flex;
  justify-content: end;
}

.NavMenu li {
  display: inline-block;
}

.NavMenu li input {
  display: none;
}

.NavMenu li a {
  display: block;
  padding: 20px 20px;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  position: relative;
}

.NavMenu li a label {
  cursor: pointer;
  appearance: none;
  display: block;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.NavMenu li a label::after {
  content: '';
  position: absolute;
  right: 0 !important;
  font-size: 12px;
}

.NavMenu > li > a label::after {
  right: -15px !important;
  top: -3px;
}

.NavMenu li ul {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  min-width: 200px;
  border-bottom: 2px solid var(--chakra-colors-primary);
  top: 100%;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transform: translateY(10px);
  -webkit-transition: translateY(10px);
  -moz-transition: translateY(10px);
  -ms-transition: translateY(10px);
  -o-transition: translateY(10px);
  visibility: hidden;
  opacity: 0;
}

.NavMenu li ul li {
  position: relative;
}

.NavMenu li ul li a {
  color: var(--chakra-colors-primary);
  padding: 8px 10px;
  display: block;
  border-left: 2px solid transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.NavMenu li ul li ul {
  position: absolute;
  left: 100%;
  top: 0;
}

@media (min-width: 992px) {
  .NavMenu li ul li a:hover {
    border-left: 2px solid var(--chakra-colors-primary);
  }

  .NavMenu li:hover > ul,
  .Navmenu li ul li:hover > ul {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
  }
}

@media (max-width: 991.98px) {
  header {
    padding: 6px 5%;
  }

  .logo {
    flex: 6;
  }

  .bartoggle {
    display: flex;
    justify-content: center;
    font-size: 30px;
    align-items: center;
    color: #fff !important;
    cursor: pointer;
  }

  .NavMenu {
    width: 500px;
    flex: 12;
    position: fixed;
    flex-direction: column;
    background-color: var(--chakra-colors-primary);
    left: 0;
    top: 40px;
    height: 100vh;
    z-index: -1;
    padding: 15px 0 50px 10px;
    justify-content: start;
    overflow-y: scroll;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
  }

  .NavMenu li ul,
  .NavMenu li ul li ul {
    position: initial;
    left: 0;
    visibility: visible;
    opacity: 1;
    top: 0;
    display: none;
  }

  .NavMenu li a {
    padding: 8px 15px;
    border-bottom: 1px solid #fff;
  }

  .NavMenu li ul li ul {
    background: var(--chakra-colors-primary);
    position: inherit;
    margin-top: -10px;
  }

  .NavMenu li ul li ul li a {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    text-transform: initial;
    padding: 7px 15px 7px 15px;
  }

  .NavMenu li a label::after {
    right: 10px;
  }

  .NavMenu li input:checked + ul,
  .NavMenu li ul li input:checked + ul {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }

  input:checked + .NavMenu {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }
}

@media (max-width: 820px) {
  .NavMenu {
    width: 100%;
  }
}

//chat
.chatMenu-scroll {
  height: 570px;
  overflow-y: auto;
}

.parentChat-scroll {
  height: 570px;
}

.bg-ddd {
  background-color: #ddd;
}

.chat-textscroll {
  height: 418px;
}

.overlays {
  background: #00000028;
}

.chat-bubble {
  background-color: #fff;
  padding: 10px 24px;
  border-radius: 8px;
  display: inline-block;
  margin-bottom: 20px;
}

.typing {
  align-items: center;
  display: flex;
  height: 17px;
}

.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #fff; //rgba(20,105,69,.7);
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}

.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}

.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}

.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}

.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #3b82f6; // rgba(20,105,69,.7);
  }

  28% {
    transform: translateY(-7px);
    background-color: #8092ad; //rgba(20,105,69,.4);
  }

  44% {
    transform: translateY(0px);
    background-color: #c2cddf; //rgba(20,105,69,.2);
  }
}

.rounded-4px {
  border-radius: 2px;
}

.usertrue {
  right: -2px;
  bottom: -2px;
}

.bg-blue200 {
  background: #bfdbfe !important;
  color: #4b5563 !important;
  height: 42px !important;
  padding: 0.5rem !important;
  border-radius: 0.125rem !important;
}

.infinite-scroll-component {
  overflow: visible !important;
}

/// Header 5 CSS

nav {
  position: fixed;
  z-index: 99;
  width: 100%;
  background: var(--chakra-colors-primary);
}

nav .wrapper {
  position: relative;
  max-width: 1300px;
  padding: 0px 30px;
  height: 70px;
  line-height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper .logo a {
  color: #f2f2f2;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}

.wrapper .nav-links {
  display: inline-flex;
}

.nav-links li {
  list-style: none;
}

.nav-links li a {
  color: #f2f2f2;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  padding: 9px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.nav-links li a:hover {
  background: var(--chakra-colors-secondary);
}

.nav-links .mobile-item {
  display: none;
}

.nav-links .drop-menu {
  position: absolute;
  background: var(--chakra-colors-primary);
  width: 180px;
  line-height: 45px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box {
  transition: all 0.3s ease;
  top: 70px;
  opacity: 1;
  visibility: visible;
}

.drop-menu li a {
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 400;
  border-radius: 0px;
}

.mega-box {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 30px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
}

.mega-box .content {
  background: var(--chakra-colors-primary);
  padding: 25px 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.mega-box .content .row {
  width: calc(25% - 30px);
  line-height: 45px;
}

.content .row img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content .row header {
  color: #f2f2f2;
  font-size: 20px;
  font-weight: 500;
}

.content .row .mega-links {
  margin-left: -40px;
  border-left: 1px solid rgba(255, 255, 255, 0.09);
}

.row .mega-links li {
  padding: 0 20px;
}

.row .mega-links li a {
  padding: 0px;
  padding: 0 20px;
  color: #d9d9d9;
  font-size: 17px;
  display: block;
}

.row .mega-links li a:hover {
  color: #f2f2f2;
}

.wrapper .btn {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}

.wrapper .btn.close-btn {
  position: absolute;
  right: 30px;
  top: 10px;
}

@media screen and (max-width: 970px) {
  .wrapper .btn {
    display: block;
  }

  .wrapper .nav-links {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0px;
    background: #242526;
    display: block;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.18);
    transition: all 0.3s ease;
  }

  /* custom scroll bar */

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #242526;
  }

  ::-webkit-scrollbar-thumb {
    background: #3a3b3c;
  }

  #menu-btn:checked ~ .nav-links {
    left: 0%;
  }

  #menu-btn:checked ~ .btn.menu-btn {
    display: none;
  }

  #close-btn:checked ~ .btn.menu-btn {
    display: block;
  }

  .nav-links li {
    margin: 15px 10px;
  }

  .nav-links li a {
    padding: 0 20px;
    display: block;
    font-size: 20px;
  }

  .nav-links .drop-menu {
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
  }

  #showDrop:checked ~ .drop-menu,
  #showMega:checked ~ .mega-box {
    max-height: 100%;
  }

  .nav-links .desktop-item {
    display: none;
  }

  .nav-links .mobile-item {
    display: block;
    color: #f2f2f2;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
  }

  .nav-links .mobile-item:hover {
    background: #3a3b3c;
  }

  .drop-menu li {
    margin: 0;
  }

  .drop-menu li a {
    border-radius: 5px;
    font-size: 18px;
  }

  .mega-box {
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    padding: 0 20px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
  }

  .mega-box .content {
    box-shadow: none;
    flex-direction: column;
    padding: 20px 20px 0 20px;
  }

  .mega-box .content .row {
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
  }

  .mega-box .content .row:nth-child(1),
  .mega-box .content .row:nth-child(2) {
    border-top: 0px;
  }

  .content .row .mega-links {
    border-left: 0px;
    padding-left: 15px;
  }

  .row .mega-links li {
    margin: 0;
  }

  .content .row header {
    font-size: 19px;
  }
}

nav input {
  display: none;
}

.detailing-overlay {
  position: relative;
}
.detailing-content {
  background-color: #118abee3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.detailing-card-shadow {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25) !important;
}

// Hedaer6 CSS

.header6 {
  background-color: #ffffff;
}

.header-6-sub-menu {
  display: block;
  border-bottom: 1px solid #eaf0f6;
}

.header-6-sub-menu-container {
  display: flex;
  height: 3.5rem;
  justify-content: space-between;
  align-items: center;
}

.header-6-menu {
  display: block;
  border-bottom: 1px solid #eaf0f6;
  position: relative;
  transition: top 0.3s;
  z-index: 9;
  background-color: white;
}
.header-6-menu:hover {
  transition: top 0.3s;
}
.header-6-menu-container {
  display: flex;
  height: 4.5rem;
  justify-content: space-between;
  align-items: center;
}

.header-6-Sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  background-color: #fff !important;
}
// .header-6-Sticky .sac {
//   display: none;
// }

.header-6-dropdown {
  list-style: none;
  // margin-left: -10px;
  // margin-top: -30px !important;
  // padding-top: 22px !important;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100%;
  left: -18px;
  // background-color: #333;
  display: block;
  min-width: 160px;
  margin-left: -15px;
}

.header-6-menu-container ul .li-height {
  height: 4.5rem;
  border-bottom: 3px solid transparent;
}

.header-6-menu-container ul .li-height:hover {
  border-bottom: 3px solid var(--chakra-colors-primary);
}

.header-6-dropdown-item {
  padding: 8px 16px;
  width: 250px;
}

.header-6-dropdown-item:hover {
  background-color: rgba(221, 221, 221, 1) !important;
  color: var(--chakra-colors-primary) !important;
}

.header-6-megaMenu {
  position: absolute;
  top: 100%;
  left: 10%;
  // margin: auto;
  padding-top: -25px !important;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-width: 80%;
  width: 80%;
  z-index: 1;
}

.header-6-megaMenu-menu {
  padding-right: 20px;
}

.header-6-megaMenu-menu li {
  padding: 8px 0;
  margin-left: 40px;
}

.header-6-megaMenu-menu-link {
  text-decoration: none;
  display: block;
  font-weight: 400;
  font-size: 14px;
}

.header-6-megaMenu-menu-link:hover {
  font-size: 14px;
  color: var(--chakra-colors-primary) !important;
}

// .header-6-sub-menu-container,
// .header-6-menu-container {
//   flex-wrap: wrap;
// }
@media (max-width: 820px) and (min-width: 642px) {
  .header-6-megaMenu-menu {
    margin-bottom: 20px;
  }
}

@media (max-width: 641px) {
  .header-6-menu-container ul {
    display: none;
  }

  .header-6-menu-container .block {
    display: block;
  }

  .header-6-dropdown {
    position: static;
    // margin-top: 8px;
  }

  .header-6-megaMenu {
    position: static;
    grid-template-columns: 1fr;
  }

  .header-6-megaMenu {
    padding: 1rem 1rem 0 1rem;
    width: 100%;
  }

  .header-6-megaMenu-menu {
    margin-bottom: 12px;
    margin-right: 0px;
    padding-right: 0px;
  }

  .header-6-dropdown-item {
    padding: 6px 32px;
  }

  .header-6-sub-menu-container {
    display: none;
    height: auto;
  }

  .header-6-megaMenu-menu li {
    padding: 4px 0;
    margin-left: 0px;
  }
}

.z-index9999 {
  z-index: 9999;
}

.header6Height {
  height: 4.5rem;
}

.header-6-sub-menu-container ul li:hover {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
.showheader {
  display: none;
}

.hovermenu:hover .showheader {
  display: block;
}

.bg-pinkLight {
  background-color: rgba(255, 245, 252, 1);
}

.header6SubDropdownDiv {
  // background-color: #04aa6d;
  // color: #000;
  border: none;
}

.header6SubDropdown {
  position: relative;
  display: inline-block;
}

.header6SubDropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 111;
  left: -90px;
  border-radius: 5px;
}

.header6SubDropdown-content a {
  color: black;
  padding: 8px 12px;
  text-decoration: none;
  display: block;
  // border-radius: 5px;
}

.header6SubDropdown-content a:hover {
  background-color: #dddddd42;
  color: var(--chakra-colors-primary) !important;
}

.header6SubDropdown:hover .header6SubDropdown-content {
  display: block;
}

.bottom-border-4f5765 {
  border-bottom: 1px solid #4f5765;
}

.bottom-border-e2e8f0 {
  border-bottom: 1px solid #e2e8f0 !important;
}

.z-index9999 {
  z-index: 9999;
}
.about37-hov {
  display: none;
}
.about37:hover .about37-hov {
  display: block;
  position: absolute;
  top: 0;
}
.rotate-text {
  writing-mode: vertical-lr !important;
  rotate: 180deg !important;
  @media (max-width: 640px) {
    writing-mode: horizontal-tb !important;
    rotate: 0deg !important;
  }
}
.h-gallery12 {
  height: 600px !important;
  @media (max-width: 768px) and (min-width: 641px) {
    height: 400px !important;
  }
  @media (max-width: 640px) {
    height: 200px !important;
  }
}
.h-minigallery12 {
  height: 290px !important;
  @media (max-width: 768px) and (min-width: 641px) {
    height: 190px !important;
  }
  @media (max-width: 640px) {
    height: 200px !important;
  }
}
@media (max-width: 640px) {
  .sm-h-auto {
    height: auto !important;
  }
}
.bg-fcfcfc2e {
  background-color: #fcfcfc2e;
}

.testimonial16-img {
  width: 100%;
  height: 400px;
}

.aboutminus-mb {
  margin-bottom: -25px !important;
}
.aboutminus-mt {
  margin-top: -25% !important;
}
.bg-glass2 {
  background: rgba(225, 225, 225, 0.2);
  filter: blur(0.3);
}
@media (max-width: 768px) and (min-width: 641px) {
  .mdh-300px {
    height: 300px !important;
  }
}
.opacity-05 {
  opacity: 0.5 !important;
}
.cust-absl {
  position: absolute;
  top: 15%;
  left: 50%;
  -webkit-transform-origin-x: -50%;
  transform: translate(-50%);
}
.abslform1 {
  position: absolute;
  bottom: -12px;
  left: 18px;
}

.abslform-card {
  position: absolute;
  bottom: -280px;
  left: 50%;
  -webkit-transform-origin-x: -50%;
  transform: translate(-50%);
}
.absl-faq6 {
  position: absolute;
}
.h-750px {
  height: 750px;
}
.smooth-dnd-disable-touch-action * .custom-touch {
  touch-action: manipulation !important;
}
.custom::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.tablist-border {
  border-top: 1px solid #a5a5a5 !important;
  border-bottom: 1px solid #a5a5a5 !important;
  padding: 12px 0px;
}

.bdr-right-d9d0d0 {
  border-right: 1px solid #d9d0d0 !important;
}
.about41 .css-1pjn7in {
  border-bottom: 0px !important;
}
.about42-top-absl {
  position: absolute;
  top: 22%;
  left: 16%;
}
.about42-bot-absl {
  position: absolute;
  bottom: 24%;
  right: 16%;
}

.showheader {
  display: none;
}

.hovermenu:hover .showheader {
  display: block;
}
.borderwid {
  border-width: 4px !important;
}

body > .skiptranslate {
  display: none;
}
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
body {
  top: 0px !important;
}
@media print {
  #google_translate_element {
    display: none;
  }
}
.goog-te-combo:nth-child(2) {
  display: none;
}

.skiptranslate.goog-te-gadget:nth-child(2) {
  display: none !important;
}
.wid-google {
  width: 150px !important;
}
.goog-te-gadget span {
  display: none;
}
.goog-te-gadget select {
  border: 1px solid #ececec;
  border-radius: 4px;
}

.goog-te-gadget .goog-te-combo {
  height: 35px !important;
  margin: 6px 0 !important;
}

.goog-te-gadget {
  padding-top: 1.5rem;
}
.skiptranslate.goog-te-gadget{
  height: 46px !important;
  overflow: hidden;
}

.w-400px {
  width: 400px;
}

@media (max-width: 641px) {
  .sm-w-300px {
    width: 300px;
  }
}

.border-dash-bottom-light-gray {
  border-bottom: 2px dashed #80808069 !important;
}

@media (max-width: 768px) {
  .md-w-5rem {
    width: 5rem;
  }
}

.border-darkMode {
  border: 1px solid #57606e;
}

.dark-bottom-border {
  border-bottom: 1px solid #eaf0f638;
}

.text-underlines {
  text-underline-offset: 10px !important;
}
@media (max-width: 640px) {
  .min-wtab {
    min-width: 100% !important;
  }
  .sm-overflow-auto {
    overflow: auto !important;
    width: 100% !important;
    padding: 3px !important;
  }
  .sm-overflow-auto::-webkit-scrollbar {
    height: 8px !important;
  }
}

// Search Pop-up

.openSearch {
  background-color: #f1f1f1;
  border: none;
  padding: 10px 15px;
  font-size: 20px;
  cursor: pointer;
}

.openSearch:hover {
  background-color: #bbb;
}

.search-overlay {
  height: 100%;
  width: 100%;
  display: none;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}

.search-overlay-content {
  top: 30px;
  width: 75%;
  text-align: center;
  margin-top: 30px;
  margin: auto;
}

.search-overlay .closebtn {
  cursor: pointer;
  color: white;
}

.search-overlay .closebtn:hover {
  color: #ccc;
}

.search-overlay input {
  // padding-right: 5%;
  color: #000;
  background-color: #fff;
}

.search-overlay button {
  position: absolute;
  right: 0;
  width: 5%;
  padding: 15px;
  background: transparent;
  color: #000;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.search-overlay button:hover {
  background: #bbb;
}

@media (max-width: 640px) {
  .search-overlay-content {
    top: 30px;
    width: 90%;
    text-align: center;
    margin-top: 30px;
    margin: auto;
  }
}

// Search Pop-up css

.bg-feefc4 {
  background-color: #feefc4;
}

.filled-social-icons {
  padding: 4px;
  background: #494949;
  border-radius: 100%;
}
.filled-social-icons-darkmode {
  padding: 4px;
  background: transparent;
}
.fill-icon-white {
  fill: #ffffff;
}

.reviewcard {
  position: absolute !important;
  top: -40px;
  right: 10%;
  z-index: 1;
}
.reviewcard2 {
  position: absolute !important;
  bottom: -40px;
  right: 10%;
  z-index: 1;
  @media (max-width: 640px) {
    position: absolute !important;
    top: -40px;
    right: 10%;
    z-index: 1;
  }
}
.addbanner-text {
  font-size: 80px !important;
  color: transparent !important;
  -webkit-text-stroke: 1px var(--chakra-colors-primary) !important;
  line-height: 100px !important;
  @media (max-width:768px) and (min-width:641px) {
    font-size: 50px !important;
    line-height: 70px !important;
  }
  @media (max-width:640px) {
    font-size: 50px !important;
    line-height: 60px !important;
  }
}

.prod13-cust .chakra-accordion__button{
  padding-left: 0px;
  padding-right: 0px;
}
.prod13-coll.chakra-accordion__panel{
  padding-left: 0px;
  padding-right: 0px;
}
.prod13-cust .chakra-accordion__item{
 border-top: 0px;
 border-bottom: 0px; 
}

.bord-btn{
  border-radius: 20px 0 0 0  !important;
}
.border1 {
  border: 1px solid #eceff7 !important;
}
.border-20px {
  border: 20px solid var(--chakra-colors-primary) !important;
}
.border-16px {
  border: 16px solid var(--chakra-colors-primary) !important;
}
.border-26px {
  border: 26px solid #fff !important;
}
.absl-news9-1{
  left: -50px;
  top: -50px;
}
.absl-news9-2{
  left: -24px;
  bottom: -28px;
}
.absl-news9-3{
  bottom: -103px;
  right: -47px;
}
.absl-news16-top{
  top: -20px;
    left: -35px;
}
.absl-news16-bot{
  bottom: -57px;
  right: -57px;
}

.newsletter10-button {
  border-radius: 25px 0px 25px 25px !important;
  padding: 20px 36px !important;
  font-weight: 500 !important; 
}

.newsletter10-circle {
  bottom: -80px;
  right: -80px;

  @media (max-width: 820px) and (min-width:641px) {
    bottom: -120px;
    right: -100px;
  }
}


.lead-1{
  margin-top: -170px;
  z-index: 1;
  position: relative;
}
.lead-2{
  margin-top: -310px;
}
.discount-3 {
  margin-top: -70px;
  z-index: 1;
  position: relative;
}
.clent9-img{
  width: 200px !important;
  height: 200px !important;
  @media (max-width:768px) and (min-width:641px) {
    width: 160px !important;
    height: 160px !important;
  }
  @media (max-width:640px)  {
    width: 160px !important;
    height: 160px !important;
  }
}

.help1-absl{
  position: absolute;
    left: 50%;
    -webkit-transform-origin-x: -50%;
    transform: translate(-50%);
    top: -40%;
}

.pt-trasnlt .goog-te-gadget{
  padding: 0px;
}
