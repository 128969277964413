$color: pink;

// .buttonAnimate {
//   display: inline-block;
//   border-radius: 4px;
//   color: #fff;
//   font-size: 1rem;
//   transition: all 0.3s;
//   position: relative;
//   overflow: hidden;
//   z-index: 1;
//   &:after {
//     content: '';
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: #9281d130;
//     border-radius: 4px;
//     z-index: -2;
//   }

//   &:before {
//     content: '';
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 0%;
//     height: 100%;
//     background-color: var(--chakra-colors-primary);
//     transition: all 0.3s;
//     border-radius: 2px;
//     z-index: -1;
//   }

//   &:hover {
//     color: #fff !important;

//     &:before {
//       width: 100%;
//     }
//   }

//   @media (max-width: 640px) {
//     padding: 0.75rem 1rem;
//     font-size: 0.75rem;
//   }
// }

// feather-card

.header2 {
  line-height: 1;
}

// .header2::before {
//   content: '';
//   width: 0;
//   height: 0;
//   border: 8px solid transparent;
//   border-bottom-color: #ff69b4;
//   position: absolute;
//   bottom: 2px;
//   border-left-color: #ff69b4;
//   right: -30px;
// }///

// .header2::after {
//   content: '';
//   width: 0;
//   height: 0;
//   border: 8px solid transparent;
//   border-bottom-color: #ff69b4;
//   position: absolute;
//   bottom: 2px;
//   border-right-color: #ff69b4;
//   left: -30px;
// }

.item-card {
  // border: 1px solid #e0e0e0 !important;
  padding: 48px;
  border-radius: 12px;
  position: relative;
  background: #fff;
  transition: 0.3s;
  @media (max-width: 820px) and (min-width: 641px) {
    padding: 36px;
  }

  @media (max-width: 640px) {
    padding: 24px;
  }
}

.item-card:hover {
  box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.1);
  border-color: #fff;
  // border: 0px solid #e0e0e0 !important;
}

.item-card1 {
  // border: 1px solid #e0e0e0 !important;
  padding: 48px;
  border-radius: 12px;
  position: relative;
  background: #fff;
  transition: 0.3s;
  @media (max-width: 820px) and (min-width: 641px) {
    padding: 36px;
  }

  @media (max-width: 640px) {
    padding: 24px;
  }
}

.item-card1::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--chakra-colors-primary);
  transition: 0.3s;
  z-index: -1;
  border-radius: 12px;
}



.item-card1:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-color: #fff;
}

.item-card1:hover::after {
  transform: rotate(-10deg);
}

.item-card11 {
  // border: 1px solid #e0e0e0 !important;
  padding: 48px;
  border-radius: 12px;
  position: relative;
  background: #fff;
  transition: 0.3s;
  @media (max-width: 820px) and (min-width: 641px) {
    padding: 36px;
  }

  @media (max-width: 640px) {
    padding: 24px;
  }
}

.item-card11::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #6c788d3b;
  transition: 0.3s;
  z-index: -1;
  border-radius: 12px;
}

.item-card11:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-color: #fff;
}

.item-card11:hover::after {
  transform: rotate(-10deg);
}

.bg-1a2540 {
  background-color: #1a2540;
}

.item-card2 {
  border-width: 1px;
  padding: 48px 36px;
  text-align: center;
  border-radius: 10px;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  @media (max-width: 820px) and (min-width: 641px) {
    padding: 28px;
  }

  @media (max-width: 640px) {
    padding: 24px;
  }
}

.item-card2-dark {
  // border-width: 1px;
  padding: 48px 36px;
  text-align: center;
  border-radius: 10px;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  @media (max-width: 820px) and (min-width: 641px) {
    padding: 28px;
  }

  @media (max-width: 640px) {
    padding: 24px;
  }
}

.item-card2:hover {
  background: #fff;
  transform: scale(1.05);
}

.item-card2-dark:hover {
  background: transparent;
  transform: scale(1.05);
}

.item-card2 h3 {
  color: #fff;
}

.item-card2:hover h3 {
  color: #000;
}

.clr-b0aac0 {
  color: #b0aac0;
}

.icon-box {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  // background: var(--chakra-colors-primary) ;
  line-height: 60px;
  text-align: center;
  color: #fff;
  margin-right: 24px;
  font-size: 24px;
  @media (max-width: 640px) {
    width: 50px;
    height: 50px;
    margin-right: 12px;
  }
}

.form-control {
  display: block !important;
  width: 100%;
  padding: 1.25rem 0.75rem !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  border: 1px solid #e2e8f0  !important;
  border-radius: 4px !important;
}

.form-control:focus-visible {
  z-index: 1;
  border-color: var(--chakra-colors-primary) !important;
  box-shadow: none !important;
}

.portfoliogrid {
  position: relative;
  width: 270px;
  height: 270px;
  margin: 10px;
  padding: 0;
  list-style: none;
  cursor: pointer;
  float: left;
  -webkit-perspective: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  visibility: visible;
  backface-visibility: hidden;
}

.portfoliogrid img {
  max-width: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
}

.iframe-size {
  width: 600px !important;
  height: 450px !important;
  @media (max-width: 820px) and (min-width: 641px) {
    width: 100% !important;
    height: 375px !important;
  }
  @media (max-width: 640px) {
    width: 100% !important;
    height: 300px !important;
  }
}

.bg-e2ecf6 {
  background-color: #e2ecf6;
}

.border-e0e0e0 {
  border: 1px solid #e0e0e0 !important;
}

.border-e0e0e0:hover {
  border: 0px solid #e0e0e0 !important;
}

.br-bottom-c8ccd4 {
  border-bottom: 1px solid #c8ccd4;
}

.shake:hover {
  box-shadow: 0px 5px 60px 0px rgba(2, 24, 93, 0.15);
  // box-shadow: 0px 5px 60px 0px rgba(2, 24, 93, 0.55);
}

.shakeDark:hover {
  // box-shadow: 0px 5px 60px 0px rgba(2, 24, 93, 0.15);
  box-shadow: 0px 5px 60px 0px rgba(2, 24, 93, 0.55);
}

.clr-80db66 {
  color: #80db66;
}

.transition3 {
  transition: 0.3s;

  -webkit-transition: 0.3s;
}

.hover-mt20px:hover {
  margin-top: -20px;
}

// .shad:hover {
//   box-shadow: 0px 5px 60px 0px rgba(2, 24, 93, 0.15);
// }

.shad:hover {
  box-shadow: 0px 5px 60px 0px rgba(24, 24, 93, 0.25);
}
